<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid>
      <v-row
        align-content="top"
        justify="center">
        <v-col
          cols="12"
          sm="8"
          md="4">
          <div class='text-center text-h5 text--lighten-1'>
            <p class='mb-3'>
              Vous êtes hors-ligne
            </p>
            <v-progress-circular
              v-if='reconnecting'
              indeterminate />
            <svg v-else
              xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4c-1.48 0-2.85.43-4.01 1.17l1.46 1.46C10.21 6.23 11.08 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3 0 1.13-.64 2.11-1.56 2.62l1.45 1.45C23.16 18.16 24 16.68 24 15c0-2.64-2.05-4.78-4.65-4.96zM3 5.27l2.75 2.74C2.56 8.15 0 10.77 0 14c0 3.31 2.69 6 6 6h11.73l2 2L21 20.73 4.27 4 3 5.27zM7.73 10l8 8H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h1.73z"/></svg>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Offline',
  computed: {
    ...mapState({
      onlineStatus: state => state.online.value
    }),
    ...mapGetters(
      {
        reconnecting: "online/reconnecting",
      }
    ),
  }
}
</script>
