import { userService } from '@/_services';
import { nowLoading, loadingComplete } from '@/_helpers';
import i18n from '@/i18n.js'

const getDefaultState = () => {
  let storedUser = null

  try {
    storedUser = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }

  return storedUser ? storedUser : {};
}
const state = getDefaultState();

const actions = {
  current({ commit, dispatch }) {
    nowLoading('retrievingUser')

    return userService.current()
      .then(
        (response) => {
          loadingComplete('retrievingUser')

          if (response.isAxiosError) {
            dispatch('alert/error', response.message, { root: true });
          } else {
            commit('getUserSuccess', response);
          }
        }
      );
  },

  updatePassword({ dispatch }, { currentPassword, newPassword }) {
    nowLoading('updatingPassword')

    return userService.updatePassword({ current_password: currentPassword, new_password: newPassword })
      .then(response => {
        if (response.isAxiosError) {
          dispatch('alert/error', response.message, { root: true });
        } else {
          dispatch('alert/success', 'Mot de passe mis à jour', { root: true });
        }
      }).catch( error => {
        let message;
        if( error.response?.data ) {
          message = error.response?.data?.errors?.map(e =>
            i18n.t('errors.'+e.code) + (e.detail ? ' ('+i18n.t('errors.'+e.detail)+')' : '')
          ).join(', ');
        }
        if (!message) {
          message = i18n.t('errors.something_went_wrong') + (typeof error == 'string' ? "("+error+")" : "")
        }
        dispatch('alert/error', message, { root: true });
      }).finally(() => {
        loadingComplete('updatingPassword')
      });
  },

  reset ({ commit }) {
    commit('resetState')
  },
};

const getters = { }

const mutations = {
  getUserSuccess(state, { user }) {
    Object.assign(state, user)

    localStorage.setItem('user', JSON.stringify(state))
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
