import { axiosFetcher } from '@/_helpers';

export const userService = {
  current,
  updatePassword,
};

function current() {
  return axiosFetcher(`/owner`)
    .then(response => {
      return response;
    }, (error) => {
      return error
    })
}

function updatePassword(data) {
  const requestOptions = {
    method: 'PATCH',
    data
  };

  return axiosFetcher(`/owner/password`, requestOptions)
}
