<template>
  <div>
    <ValidationObserver ref="observerLoginForm" v-slot="{ invalid }">
      <div class="px-4">
        <p class="text-center">1. Entrez l'adresse email du client.</p>

        <div>
          <ValidationProvider
            ref="emailValidationProvider"
            name="'email'"
            rules="required|email"
            v-slot="{ errors, valid }"
          >
            <v-text-field
              id="field--user-email"
              v-model="email"
              label="Adresse email"
              class="mt-2 mb-2"
              :disabled="disabled"
              :success="valid"
              :error-messages="errors"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              type="email"
              outlined
            />
          </ValidationProvider>
        </div>
      </div>

      <div class="px-4">
        <p class="text-center">
          2. Sélectionnez les contenants retournés ou empruntés.
        </p>

        <v-alert dense outlined text border="left" icon="mdi-cash" type="info">
          {{ totalCostDetails }}
        </v-alert>
      </div>

      <v-expansion-panels tile :disabled="disabled">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <return-icon :width="24" :height="24" class="mr-4"></return-icon>
              {{ $t(`request.returns_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <format-count-selector
              :value="returns"
              :availableFormats="allFormats"
              :action="'returns'"
              :disabled="disabled"
              @update-format-count="updateFormatCount($event)"
            >
              <template v-slot:alert v-if="noReuseLoop">
                {{ $t(`request.restaurant_no_loop`) }}
              </template>
            </format-count-selector>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <borrow-icon :width="24" :height="24" class="mr-4"></borrow-icon>
              {{ $t(`request.grabs_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <format-count-selector
              :value="grabs"
              :availableFormats="restaurantAvailableFormats"
              :action="'grabs'"
              :disabled="disabled"
              @update-format-count="updateFormatCount($event)"
            >
              <template v-slot:alert v-if="noReuseLoop">
                {{ $t(`request.restaurant_no_loop`) }}
              </template>
              <template v-slot:alert v-else-if="restaurantNoContainer">
                {{ $t(`request.restaurant_no_container`) }}
              </template>
            </format-count-selector>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="position-bottom-fixed">
        <v-divider></v-divider>

        <div class="d-flex justify-space-around py-2 white">
          <v-btn rounded outlined large @click="cancel">
            {{ $t("shared.cancel") }}
          </v-btn>

          <v-btn
            rounded
            :disabled="invalid || isEmpty || disabled || checkingGuestEmail"
            :loading="checkingGuestEmail"
            color="primary"
            large
            class="px-8"
            @click="checkEmailValidity"
          >
            {{ $t("shared.continue") }}
          </v-btn>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { nowLoading, loadingComplete } from "@/_helpers";
import { displayError } from "@/_helpers";

import BorrowIcon from "@/components/icons/BorrowIcon.vue";
import ReturnIcon from "@/components/icons/ReturnIcon.vue";

import FormatCountSelector from "@/components/request/FormatCountSelector.vue";

export default {
  name: "EmailInputer",

  components: {
    FormatCountSelector,
    ValidationObserver,
    ValidationProvider,
    BorrowIcon,
    ReturnIcon
  },

  mounted() {
    this.initRequest();
  },

  data() {
    return {
      initialized: false,
      grabs: {},
      returns: {},
      email: ""
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant",
      restaurantAvailableFormats: "restaurants/availableFormats",
      allFormats: "restaurants/allFormats",
      totalCost: "request/totalCost",
      checkingGuestEmail: "loading/checkingGuestEmail"
    }),
    restaurantNoContainer() {
      return !this.restaurantAvailableFormats.length;
    },
    noReuseLoop() {
      return this.currentRestaurant.reuse_loop_level !== "grab_return_refill";
    },
    totalCostDetails() {
      if (this.totalCost <= 0)
        return this.$t(`request.total_guest_cost_preview_no_change`);

      const value = Math.abs(this.totalCost),
        value_with_currency = `${value} €`;

      return this.$t(`request.total_guest_cost_preview`, {
        amount: value_with_currency
      });
    },
    isEmpty() {
      return (
        Math.max(...Object.values(this.grabs)) +
          Math.max(...Object.values(this.returns)) ===
        0
      );
    }
  },

  methods: {
    ...mapActions({
      checkEmail: "request/checkEmail",
      refreshCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),
    ...mapMutations({
      setGrabs: "request/setGrabs",
      setReturns: "request/setReturns",
      setEmail: "request/setEmail"
    }),
    initRequest() {
      this.grabs = this.restaurantAvailableFormats.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: 0 }),
        {}
      );
      this.returns = this.allFormats.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: 0 }),
        {}
      );

      this.initialized = true;
    },
    updateFormatCount({ value, formatId, action }) {
      if (action === "returns") {
        this.returns[formatId] = value;
        this.setReturns(this.returns);
      } else if (action === "grabs") {
        this.grabs[formatId] = value;
        this.setGrabs(this.grabs);
      }
    },
    checkEmailValidity() {
      nowLoading("checkingGuestEmail");
      const uuid = this.currentRestaurant.uuid;
      this.checkEmail(uuid).then(valid => {
        loadingComplete("checkingGuestEmail");

        if (valid) this.gotoReview();
        else displayError("Un client existe déjà avec cet email");
      });
    },
    gotoReview() {
      this.$emit("goto-review");
    },
    cancel() {
      this.$emit("close");
    }
  },

  watch: {
    email(value) {
      this.setEmail(value);
    }
  }
};
</script>
