/* eslint-disable no-underscore-dangle */
import { extend } from 'vee-validate';
import { required, max, min, email, confirmed } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

extend('required', {
  ...required,
  message: (_, values) => i18n.t('validation.required', values),
});

extend('confirmed', {
  ...confirmed,
  message: (_, values) => i18n.t('validation.confirmed', values),
});

extend('max', {
  ...max,
  message: (_, values) => i18n.t('validation.max', values),
});

extend('min', {
  ...min,
  message: (_, values) => i18n.t('validation.min', values),
});

extend('email', {
  ...email,
  message: (_, values) => i18n.t('validation.email', values),
});

extend('confirmed', {
  ...confirmed,
  message: (_, values) => i18n.t('validation.confirmed', values),
});