<template>
  <div class="mt-4 px-2 px-sm-0">
    <v-card class="mb-2" v-if="currentRestaurant">
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Vous êtes</v-list-item-title>
            <v-list-item-subtitle>
              <span class="text-h5">{{ currentRestaurant.name }}</span
              ><br />
              {{ currentRestaurant.address_1 }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn @click="editRestaurant" icon>
              <v-icon color="grey lighten-1" small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="hasManyRestaurants" @click="changeRestaurant" icon>
              <v-icon color="grey lighten-1">mdi-format-list-checks</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <div>
      <v-card class="mb-2">
        <v-card-text>
          <p class="text-center">Identifier le client</p>

          <v-layout align-center justify-center>
            <v-btn
              color="primary"
              large
              rounded
              @click="openRequestCreator('webcam')"
              class="mx-2"
            >
              <v-icon left> mdi-qrcode </v-icon>
              QR Code
            </v-btn>

            <v-btn
              color="primary"
              large
              rounded
              @click="openRequestCreator('pin')"
              class="mx-2"
            >
              <v-icon left> mdi-numeric </v-icon>
              Code PIN
            </v-btn>
          </v-layout>

          <div class="mt-3 text-center" v-if="doGrabReturnRefill">
            <p>ou</p>

            <v-btn
              :disabled="!hasContainers"
              color="primary"
              large
              rounded
              @click="openRequestCreatorForGuest"
            >
              <v-icon left> mdi-at </v-icon>
              Créer un compte client
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mb-2" v-if="doGrabReturnRefill">
        <v-card-text class="pa-2 pt-0">
          <stocks-list :show-dirty="doNotWashContainers"></stocks-list>
        </v-card-text>
      </v-card>

      <request-creator
        v-model="customerRequestDialog"
        :input-source="customerRequestInputSource"
        @close="closeRequestCreator"
      />
      <request-creator-for-guest
        v-model="guestRequestDialog"
        @close="closeRequestCreator"
      />
    </div>

    <v-dialog
      v-if="restaurants"
      v-model="selectRestaurantDialog"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          <span class="text-h5">Selectionner votre restaurant</span>
        </v-card-title>
        <v-card-text>
          <select-restaurant
            @restaurant-selected="restaurantSelected"
            :restaurants="restaurants"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="currentRestaurant"
      v-model="editRestaurantDialog"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
      <restaurant-form
        @close="editRestaurantComplete"
        @cancel="editRestaurantComplete"
        :restaurant="restaurantToEdit"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StocksList from "@/components/StocksList";

import RestaurantForm from "@/views/private/restaurants/Form";
import SelectRestaurant from "@/views/private/SelectRestaurant";
import RequestCreator from "@/views/private/RequestCreator";
import RequestCreatorForGuest from "@/views/private/RequestCreatorForGuest.vue";

export default {
  data() {
    return {
      editRestaurantDialog: false,
      selectRestaurantDialog: false,
      restaurantToEdit: null,
      customerRequestDialog: false,
      customerRequestInputSource: null,
      guestRequestDialog: false
    };
  },

  components: {
    RestaurantForm,
    SelectRestaurant,
    StocksList,
    RequestCreator,
    RequestCreatorForGuest
  },

  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant",
      hasManyRestaurants: "restaurants/hasManyRestaurants"
    }),
    ...mapState({
      restaurants: (state) => state.restaurants.restaurants
    }),

    hasContainers: function () {
      return (
        this.currentRestaurant && this.currentRestaurant.containers_count > 0
      );
    },
    doNotWashContainers() {
      return this.currentRestaurant && !this.currentRestaurant.wash_containers;
    },
    doGrabReturnRefill() {
      return (
        this.currentRestaurant &&
        this.currentRestaurant.reuse_loop_level === "grab_return_refill"
      );
    },

    sourcePinOrQrCode() {
      return this.inputSource === "pin" || this.inputSource === "webcam";
    },
    sourceEmail() {
      return this.inputSource === "email";
    }
  },

  methods: {
    ...mapActions({
      fetchCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),

    changeRestaurant() {
      this.selectRestaurantDialog = true;
    },
    restaurantSelected() {
      this.fetchCurrentRestaurantStocks();
      this.selectRestaurantDialog = false;
    },

    editRestaurant() {
      this.populateModelToEdit();
      this.editRestaurantDialog = true;
    },
    editRestaurantComplete() {
      this.restaurantToEdit = null;
      this.editRestaurantDialog = false;
    },
    async populateModelToEdit() {
      this.restaurantToEdit = Object.assign({}, this.currentRestaurant);
    },

    gotoRequest() {
      this.$router.push("/request");
    },
    openRequestCreator(source) {
      this.closeRequestCreator();

      this.customerRequestDialog = true;
      this.customerRequestInputSource = source;
    },
    openRequestCreatorForGuest() {
      this.closeRequestCreator();

      this.guestRequestDialog = true;
    },
    closeRequestCreator() {
      this.customerRequestDialog = false;
      this.guestRequestDialog = false;
      this.customerRequestInputSource = null;
    }
  }
};
</script>
