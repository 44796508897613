<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }} icon</title>
    <g :fill="iconColor">
      <rect x="0.9" y="9.6" width="7.37" height="5.91"/><rect x="9.15" y="9.6" width="7.37" height="5.91"/>
      <path d="M20.72,7.69H15.5A2.1,2.1,0,1,0,12,5.44,2.1,2.1,0,1,0,8.5,7.69H3.28v3.58h.91V13h7.37v-1.7h.88V13h7.37v-1.7h.91ZM13.89,5.12a1.23,1.23,0,1,1-1.23,1.23A1.23,1.23,0,0,1,13.89,5.12Zm-3.78,0A1.23,1.23,0,1,1,8.88,6.35,1.23,1.23,0,0,1,10.11,5.13Z" transform="translate(-3.28 -4.24)"/>
    </g>
  </svg>
</template>

<script>
export default {
  data: () => ({
    iconName: 'Offer'
  }),

  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>