const state = {
  data: []
}

const actions = {
  success({ commit }, message) {
    commit('success', message);
  },
  error({ commit }, message) {
    commit('error', message);
  },
  clear({ commit }, message) {
    commit('clear', message);
  },
  removeLast({ commit }) {
    commit('remove')
  },
};

const mutations = {
  success(state, message) {
    state.data.unshift({ type: 'success', message: message })
  },
  error(state, message) {
    state.data.unshift({ type: 'error', message: message })
  },
  clear(state) {
    state.data = []
  },
  remove(state) {
    state.data.pop()
  },
};

const getters = {
  last: state => state.data.last,
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
