/* eslint-disable */

const state = {
  status: 'online',
  tmp: 'online'
};

const actions = {
  update({ commit }, value) {
    commit('update', value);
  },
};

const mutations = {
  update(state, value) {
    if (state.status == 'offline' && state.tmp == 'offline' && value) {
      state.tmp = 'online'
    }
    else if (state.status == 'offline' && state.tmp == 'online' && value) {
      state.status = 'online'
    }
    else if (!value) {
      state.tmp = state.status = 'offline'
    }
  },
};

const getters = {
  status: state => state.value,
  reconnecting: state => state.tmp === 'online' && state.status === 'offline',
  isOnline: state => state.status === 'online'
}

export const online = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
