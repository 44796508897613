<template>
  <v-footer app dark padless color="grey darken-4">
    <v-spacer></v-spacer>
    <small
      >{{ $appConfig.name }} &copy; {{ new Date().getFullYear() }} - v{{
        currentVersion
      }}</small
    >
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
import { VERSION } from "../../../version.js";

export default {
  computed: {
    currentVersion() {
      return VERSION;
    }
  }
};
</script>
