<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }} icon</title>

    <g :fill="iconColor">
      <path d="M11.34,10.09,22,14l-.1.26a4,4,0,0,1-3.69,2.6,3.88,3.88,0,0,1-1.35-.24L13.59,15.4a3.93,3.93,0,0,1-2.35-5l.1-.27m-.78-1.67L10,9.91a5.23,5.23,0,0,0,3.13,6.71l3.22,1.18a5.43,5.43,0,0,0,1.8.32,5.25,5.25,0,0,0,4.92-3.45l.54-1.49L10.56,8.42Z" transform="translate(-0.37 -5.88)"/>
      <path d="M2,9.59a.78.78,0,0,1-.22,0l-1-.36a.65.65,0,0,1-.39-.83A.65.65,0,0,1,1.24,8l1,.35A.65.65,0,0,1,2,9.59Z" transform="translate(-0.37 -5.88)"/>
      <path d="M8,8.83a.83.83,0,0,1-.22,0L3.13,7.15a.65.65,0,0,1,.43-1.23L8.2,7.57a.65.65,0,0,1,.39.83A.63.63,0,0,1,8,8.83Z" transform="translate(-0.37 -5.88)"/>
      <path d="M8.46,14.87a.52.52,0,0,1-.21,0L3.62,13.18A.65.65,0,1,1,4.05,12l4.63,1.65a.65.65,0,0,1,.4.83A.67.67,0,0,1,8.46,14.87Z" transform="translate(-0.37 -5.88)"/>
      <path d="M7.6,11.67a.6.6,0,0,1-.22,0l-3.64-1.3A.65.65,0,0,1,4.18,9.1l3.64,1.31a.65.65,0,0,1-.22,1.26Z" transform="translate(-0.37 -5.88)"/>
    </g>
  </svg>
</template>

<script>
export default {
  data: () => ({
    iconName: 'Return'
  }),

  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>