<template>
  <div>
    <table-summary v-model="requestReview" />

    <div
      class="d-flex flex-column align-content-space-around flex-wrap py-2 mt-8"
    >
      <v-btn
        rounded
        color="primary"
        large
        @click="submit"
        class="px-8 mb-4"
        :loading="submittingRequest"
      >
        {{ totalCostDetails }}
      </v-btn>

      <v-btn text @click="cancel" :disabled="submittingRequest">
        {{ $t("request.cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import TableSummary from "@/components/request/TableSummary.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    TableSummary
  },

  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant",
      requestReview: "request/presummary",
      totalCost: "request/totalCost",
      submittingRequest: "loading/submittingRequest"
    }),

    totalCostDetails() {
      if (this.totalCost <= 0)
        return this.$t(`request.i_total_guest_cost_preview_no_change`);

      const value = Math.abs(this.totalCost),
        value_with_currency = `${value} €`;

      return this.$t(`request.i_total_guest_cost_preview`, {
        amount: value_with_currency
      });
    }
  },

  methods: {
    ...mapActions({
      submitRequest: "request/submit",
      refreshCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.submitRequest(this.currentRestaurant.uuid).then(() =>
        this.refreshCurrentRestaurantStocks()
      );
    }
  }
};
</script>
