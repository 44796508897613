<template>
  <v-card
    class='mx-2 mb-2'>
    <v-card-text class='pa-0'>
      <ValidationObserver ref="observerEditPasswordForm" v-slot="{ invalid, validated, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submit)"
          id="update-password-form">
          <v-card-text>
            <ValidationProvider name="'mot de passe actuel'" rules="required|min:4" v-slot="{ errors, valid }">
              <p>Entrez votre mot de passe actuel</p>
              <v-text-field
                prepend-icon="mdi-lock"
                :error-messages="errors"
                :success="valid"
                v-model="user.currentPassword"
                label="Mot de passe"
                id="password"
                type="password" />
            </ValidationProvider>
            <p>Entrez votre nouveau mot de passe</p>
            <ValidationProvider name="'nouveau mot de passe'" rules="required|min:4|confirmed:confirmation" v-slot="{ errors, valid }">
              <v-text-field
                ref='password'
                prepend-icon="mdi-lock"
                :error-messages="errors"
                :success="valid"
                v-model="user.newPassword"
                label="Nouveau mot de passe"
                id="newPassword"
                type="password" />
            </ValidationProvider>
            <ValidationProvider name="'confirmer mot de passe'" rules="required|min:4" vid="confirmation" v-slot="{ errors, valid }">
              <v-text-field
                prepend-icon="mdi-lock"
                :error-messages="errors"
                :success="valid"
                v-model="user.passwordConfirmation"
                label="Confirmer le mot de passe"
                id="passwordConfirmation"
                type="password" />
            </ValidationProvider>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              v-show="!updatingPassword"
              @click="$emit('cancel');"
              color="gray">{{ $t('shared.cancel') }}</v-btn>
            <v-btn
              depressed
              type="submit"
              color="primary"
              :disabled="updatingPassword || invalid || !validated"
              :loading="updatingPassword">{{ $t('shared.save') }}</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  name: 'EditPassword',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    user: {
      currentPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    submitted: false
  }),

  computed: {
    ...mapGetters({
      updatingPassword: 'loading/updatingPassword',
    }),
  },

  methods: {
    ...mapActions({
      updatePassword: 'user/updatePassword'
    }),
    submit () {
      this.updatePassword({ currentPassword: this.user.currentPassword, newPassword: this.user.newPassword });
    }
  }
}
</script>
