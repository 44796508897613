<template>
  <v-card elevation="0" class="rounded-lg">
    <div v-if='checkingTokenValidity'>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate />
      </v-card-text>
    </div>
    <div v-else>
      <div v-if='tokenValid'>
        <div v-if="!submitted">
          <ValidationObserver
            ref="observerLoginForm"
            v-slot="{ invalid, validated, handleSubmit }"
          >
            <v-form
              @submit.prevent="handleSubmit(submit)"
              id="form--login-user"
            >
              <v-card-text>
                <p>Entrez votre nouveau mot de passe.</p>
                <ValidationProvider
                  name="password"
                  rules="required|min:4"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    prepend-icon="mdi-lock"
                    data-vv-as="'mot de passe'"
                    v-model="password"
                    :error-messages="errors"
                    :success="valid"
                    label="Nouveau mot de passe"
                    type="password"
                    id="field--user-password"
                    :disabled="requestingResetPassword"
                  />
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  v-show="!requestingResetPassword"
                  id="btn--cancel-login"
                  @click="returnToHome"
                  >{{ $t("shared.cancel") }}</v-btn
                >
                <v-btn
                  depressed
                  type="submit"
                  color="primary"
                  id="btn--submit-login"
                  :disabled="invalid || !validated"
                  :loading="requestingResetPassword"
                  >Valider</v-btn
                >
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </div>

        <div v-else>
          <v-alert
            dense
            outlined
            type="success"
          >
            Votre mot de passe a été mis à jour. Vous pouvez dès à présent
            l'utiliser pour vous identifier.<br><br>
            <span class='text-decoration-underline text-cursor' @click='returnToHome'>Retourner à la page d'accueil</span>
          </v-alert>
        </div>
      </div>

      <div v-else>
        <v-alert
          dense
          outlined
          type="error"
        >
          Votre demande de réinitialisation de mot de passe est invalide ou a expiré, et ne peut pas être prise en compte.<br><br>
          <span class='error--text text-decoration-underline text-cursor' @click='returnToHome'>Retourne à la page d'accueil</span> pour faire une nouvelle demande.
        </v-alert>
      </div>
    </div>
  </v-card>
</template>

<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapGetters } from "vuex";
import { authService } from "@/_services";
import { nowLoading, loadingComplete, getQueryParam, isTokenExpired } from "@/_helpers";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    checkingTokenValidity: true,
    tokenValid: true,
    password: null,
    submitted: false,
  }),

  computed: {
    ...mapGetters({
      requestingResetPassword: "loading/requestingResetPassword",
    }),
  },

  mounted() {
    try {
      if (isTokenExpired(getQueryParam('token')))
        this.tokenValid = false
    } catch(e) {
      this.tokenValid = false
    }

    this.checkingTokenValidity = false
  },

  methods: {
    submit() {
      nowLoading("requestingResetPassword");
      const token = getQueryParam('token');

      authService.resetPassword(token, this.password).then(() => {
        this.submitted = true;
        loadingComplete("requestingResetPassword");
      });
    },
    returnToHome(e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      this.$router.push("/");
    },
  },
};
</script>
