import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store'

import OauthFlowPage from '@/views/public/OauthFlow';

import HomePage from '@/views/private/Home';
import InvoicesPage from '@/views/private/Invoices';
import SettingsPage from '@/views/private/Settings';
import TransactionsPage from '@/views/private/Transactions';

import LoginPage from '@/views/public/Login';

import ResetPasswordPage from '@/views/public/ResetPassword.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/oauth/authorize',
    component: OauthFlowPage,
    meta: {
      layout: 'empty',
      public: true,
    }
  },

  {
    path: '/home',
    component: HomePage,
    meta: {
      public: false
    },
  },

  {
    path: '/transactions',
    component: TransactionsPage,
    meta: {
      public: false
    },
  },

  {
    path: '/invoices',
    component: InvoicesPage,
    meta: {
      public: false
    },
  },

  {
    path: '/settings/:tab?',
    component: SettingsPage,
    props: true,
    meta: {
      fullscreen: false,
      public: false
    },
  },

  {
    path: '/password/reset',
    component: ResetPasswordPage,
    meta: {
      layout: 'no-navbar',
      public: true
    },
  },  

  {
    path: '/',
    component: LoginPage,
    meta: {
      layout: 'no-navbar',
      public: true,
      root: true
    }
  },

  {
    path: "/auth/callback",
    name: "AuthCallback",
    meta: {
      authCallback: true
    }
  },

  // otherwise redirect to home
  { path: '*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

router.beforeEach(async (to, from, next) => {
  if( to.meta.authCallback && to.query.error ) {
    return next("/?" + Object.keys(to.query).map(k => [k, to.query[k]].join('=')).join('&'));
  }

  // "code" is provided by the oauth screen after the callback
  if( (to.meta.root || to.meta.authCallback) && to.query.code ) {
    await exchangeCodeForToken(to.query.code);
    // user should now be authenticated
    if( userLoggedIn() ) {
      if( pendingThirdPartyRequest() ) {
        // the login was asked by the third party app auth request : restart from there
        let q = thirdPartyRequestQuery();
        clearThirdPartyRequest();
        if( !q.match('client_id=') ) {
          // malformed third party query
          return next("/home");
        } else {
          return next("/oauth/authorize?" + q);
        }
      } else {
        return next("/home");
      }
    }
  }

  if (to.meta.root && userLoggedIn()) {
    return next('/home');
  }

  // redirect to login page if not logged in and trying to access a restricted page
  if (!to.meta.public && !userLoggedIn()) {
    return next('/');
  }

  next();
});

function userLoggedIn() {
  return store.getters['auth/loggedIn'] === true
}

function exchangeCodeForToken(code) {
  return store.dispatch("auth/exchangeCodeForToken", code);
}

function pendingThirdPartyRequest() {
  return store.getters["auth/pendingThirdPartyRequest"] === true;
}

function thirdPartyRequestQuery() {
  return store.getters["auth/thirdPartyRequestQuery"];
}

function clearThirdPartyRequest() {
  return store.dispatch("auth/clearThirdPartyRequest");
}

export default router
