import { axiosFetcher } from '@/_helpers';

export const restaurantService = {
  index,
  show,
  update,
  stocks,
};

function index() {
  return axiosFetcher(`/restaurants`)
    .then(response => {
      return response;
    })
}

function show(uuid) {
  return axiosFetcher(`/${uuid}`)
    .then(response => {
      return response;
    })
}

function update(uuid, data) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      "Content-type": "multipart/form-data"
    },
    data
  };

  return axiosFetcher(`/${uuid}`, requestOptions)
    .then(response => {
      return response;
    })
}

function stocks(uuid) {
  return axiosFetcher(`/${uuid}/stocks`)
    .then(response => {
      return response;
    })
}