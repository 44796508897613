<template>
  <v-radio-group class="mt-0 pt-0" @change="selectRestaurant">
    <v-radio
      :value="restaurant.id"
      v-for="restaurant in restaurants"
      v-bind:key="restaurant.id"
    >
      <template v-slot:label>
        <div>
          {{ restaurant.name }}
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    restaurants: Array
  },

  methods: {
    ...mapMutations({
      setCurrentRestaurantId: "restaurants/setCurrentRestaurantById"
    }),

    selectRestaurant(id) {
      this.setCurrentRestaurantId(id);
      this.$emit("restaurant-selected");
    }
  }
};
</script>
