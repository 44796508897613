import { restaurantService } from '@/_services';

const state = { restaurants: [], currentRestaurantId: null, stocks: [] };

const actions = {
  index({ commit, dispatch }) {
    commit('loading/updateStatus', { key: 'retrievingRestaurants', value: true }, { root: true })

    return restaurantService.index()
      .then((restaurants) => {
        commit('getRestaurantsSuccess', restaurants);
        if (restaurants.length == 1) {
          commit('setCurrentRestaurantById', restaurants[0].id)
          dispatch('fetchCurrentRestaurantStocks')
        }

        commit('loading/updateStatus', { key: 'retrievingRestaurants', value: false }, { root: true })
      })
  },

  update({ commit }, { uuid, data }) {
    commit('loading/updateStatus', { key: 'savingRestaurant', value: true }, { root: true })

    return restaurantService.update(uuid, data)
      .then((restaurant) => {
        commit('savingRestaurantSuccess', restaurant);
        commit('loading/updateStatus', { key: 'savingRestaurant', value: false }, { root: true })
      })
  },

  show({ commit }, uuid) {
    commit('loading/updateStatus', { key: 'retrievingRestaurant', value: true }, { root: true })

    return restaurantService.show(uuid)
      .then((restaurant) => {
        commit('getRestaurantSuccess', restaurant);
        commit('loading/updateStatus', { key: 'retrievingRestaurant', value: false }, { root: true })
      })
  },

  fetchCurrentRestaurantStocks({ getters, commit }) {
    if(!getters.currentRestaurant) return
    commit('loading/updateStatus', { key: 'retrievingStocks', value: true }, { root: true })
    
    return restaurantService.stocks(getters.currentRestaurant.uuid)
      .then((restaurantStocks) => {
        commit('setStocks', restaurantStocks);
        commit('loading/updateStatus', { key: 'retrievingStocks', value: false }, { root: true })
      })
  },

  reset({ commit }) {
    commit('resetState')
  }
};

const getters = {
  currentRestaurant: (state) => state.restaurants && state.currentRestaurantId ? state.restaurants.find((restaurant) => restaurant.id == state.currentRestaurantId) : null,
  hasManyRestaurants: (state) => state.restaurants && state.restaurants.length > 1,
  allFormats: (state) => {
    return state.stocks.map((format) => {
      return {
        id: format.id,
        title: format.title,
        subtitle: format.subtitle,
        image_url: format.image_url,
        amount: format.amount,
      }
    })
  },
  availableFormats: (state) => {
    return state.stocks.filter((format) => {
      return format.containers_count > 0;
    }).map((format) => {
      return {
        id: format.id,
        title: format.title,
        subtitle: format.subtitle,
        image_url: format.image_url,
        amount: format.amount,
        containers_count: format.containers_count,
      }
    })
  }
}

const mutations = {
  setCurrentRestaurantById(state, id) {
    state.currentRestaurantId = id
  },

  getRestaurantsSuccess(state, restaurants) {
    state.restaurants = restaurants;
  },

  getRestaurantSuccess(state, restaurant) {
    let found = false

    state.restaurants.forEach(function (item, i) {
      if (item.id == restaurant.id) {
        state.restaurants.splice(i, 1, restaurant)
        found = true
      }
    });

    if (!found)
      state.restaurants.push(restaurant)
  },

  setStocks(state, stocks) {
    Object.assign(state, { stocks: stocks });
  },

  savingRestaurantSuccess(state, restaurant) {
    state.restaurants.forEach(function (item, i) {
      if (item.id == restaurant.id) {
        state.restaurants.splice(i, 1, restaurant)
      }
    });
  },

  resetState(state) {
    state.restaurants = []
    state.currentRestaurantId = null
  }
};

export const restaurants = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
