<template>
  <v-card elevation="0">
    <div v-if="!submitted">
      <ValidationObserver
        ref="observerForgotPasswordForm"
        v-slot="{ invalid, validated, handleSubmit }"
      >
        <v-form
          @submit.prevent="handleSubmit(submit)"
          id="form--forgot-password"
        >
          <v-card-text>
            <p class="text-h5">
              Mot de passe oublié
            </p>
            <p>
              Saisissez l'adresse e-mail de votre compte
              {{ $appConfig.name }} pour réinitialiser votre mot de passe.
            </p>
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                prepend-icon="mdi-account"
                data-vv-as="'adresse email'"
                v-model="email"
                :error-messages="errors"
                :success="valid"
                label="Adresse email"
                id="field--user-email"
                :disabled="requestingForgotPassword"
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              v-show="!requestingForgotPassword"
              id="btn--cancel-login"
              @click="$emit('cancel')"
              >{{ $t("shared.cancel") }}</v-btn
            >
            <v-btn
              depressed
              type="submit"
              color="primary"
              id="btn--submit-login"
              :disabled="requestingForgotPassword || invalid || !validated"
              :loading="requestingForgotPassword"
              >Valider</v-btn
            >
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </div>

    <div v-else>
      <v-card-text>
        <p class="text-h5">
          Merci
        </p>
        <p>
          Vous allez recevoir un message sur <em>{{ email }}</em> avec un lien
          pour réinitialiser votre mot de passe.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          id="btn--cancel-lost-password"
          @click="$emit('cancel')"
          >{{ $t("shared.close") }}</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapGetters } from "vuex";
import { authService } from "@/_services";
import { nowLoading, loadingComplete } from "@/_helpers";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    email: null,
    submitted: false
  }),

  mounted: function() {
    this.email = null;
    this.submitted = false;
  },

  computed: {
    ...mapGetters({
      requestingForgotPassword: "loading/requestingForgotPassword"
    })
  },

  methods: {
    submit() {
      nowLoading("requestingForgotPassword");

      authService.forgotPassword(this.email).then(() => {
        this.submitted = true;
        loadingComplete("requestingForgotPassword");
      });
    }
  }
};
</script>
