import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: Vue.prototype.$appConfig.vuetify_themes,
  },
  lang: {
    locales: { fr, en },
    current: Vue.prototype.$appConfig.default_locale,
  },
});
