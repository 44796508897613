<template>
  <div>
    <v-tabs :grow='showDirty' show-arrows>
      <v-tab eager>{{ showDirty ? $t('restaurant_clean_stocks') : $t('restaurant_stocks') }}</v-tab>
      <v-tab eager v-if="showDirty">{{ $t('restaurant_dirty_stocks') }}</v-tab>
      <v-tab eager v-if="currentRestaurant.active_drop_off_point">{{ $t('dropoff_clean_stocks') }}</v-tab>
      <v-tab eager v-if="currentRestaurant.active_drop_off_point">{{ $t('dropoff_dirty_stocks') }}</v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <stocks-tab-item :stocks="stocks" v-slot="{ item }">
          {{ item.containers_count }}
        </stocks-tab-item>
      </v-tab-item>

      <v-tab-item
        v-if="showDirty"
        :transition="false"
        :reverse-transition="false"
      >
        <stocks-tab-item :stocks="stocks" v-slot="{ item }">
          {{ item.dirty_containers_count }}
        </stocks-tab-item>
      </v-tab-item>

      <v-tab-item v-if="currentRestaurant.active_drop_off_point" :transition="false" :reverse-transition="false">
        <stocks-tab-item :stocks="stocks">
          <template v-slot:default="{ item }">
            {{ item.drop_off_point_containers_count }}
          </template>
          <template v-slot:more="{ item }">
            <td class="text-right pr-2">
              <v-btn
                v-if="currentRestaurant.wash_drop_off_point_containers && item.drop_off_point_containers_count"
                depressed
                class="ml-4"
                @click="setupAction(item, 'pick')"
              >
                <v-icon left>mdi-silverware-fork-knife</v-icon>
                <span class="btn-label">Intégrer au resto</span>
              </v-btn>
            </td>
          </template>
        </stocks-tab-item>
        <v-btn
          v-if="currentRestaurant.wash_drop_off_point_containers && totalPossible('pick')"
          block
          color="primary"
          class="mt-2"
          @click="setupActionAll('pick')"
        >
          <v-icon left>mdi-silverware-fork-knife</v-icon>
          Intégrer tout
        </v-btn>
      </v-tab-item>

      <v-tab-item v-if="currentRestaurant.active_drop_off_point" :transition="false" :reverse-transition="false">
        <stocks-tab-item :stocks="stocks">
          <template v-slot:default="{ item }">
            {{ item.drop_off_point_dirty_containers_count }}
          </template>
          <template v-slot:more="{ item }">
            <td class="text-right pr-2">
              <v-btn
                v-if="currentRestaurant.wash_drop_off_point_containers && item.drop_off_point_dirty_containers_count"
                depressed
                class="ml-4"
                @click="setupAction(item, 'wash')"
              >
                <v-icon left> mdi-water </v-icon>
                <span class="btn-label">Signaler le lavage</span>
              </v-btn>
            </td>
          </template>
        </stocks-tab-item>
        <v-btn
          v-if="currentRestaurant.wash_drop_off_point_containers && totalPossible('wash')"
          block
          color="primary"
          class="mt-2"
          @click="setupActionAll('wash')"
        >
          <v-icon left> mdi-water </v-icon>
          Laver tout
        </v-btn>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-if="currentRestaurant.wash_drop_off_point_containers"
      v-model="showPopup"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          <span class="text-h5" v-if="action=='wash'">Signaler le lavage</span>
          <span class="text-h5" v-else>Intégrer au stock du resto</span>
        </v-card-title>
        <v-card-text v-if="item">
          <span style="font-size: 2em">
            <v-btn
              icon
              large
              outlined
              @click="nb--"
              :disabled="nb <= 0"
              class="ma-0"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            {{ nb }}
            <v-btn
              icon
              large
              outlined
              @click="nb++"
              :disabled="nb >= max"
              class="ma-0"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            contenants {{ action == 'wash' ? ' lavés' : 'intégrés' }}
          </span>
          <v-radio-group v-model="integrateToRestaurantStock" v-if="action=='wash'">
            <v-radio label="intégrer au stock du restaurant" :value="true"></v-radio>
            <v-radio label="laisser dans le point de dépôt" :value="false"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-text v-else-if="action && stocks.length && totalPossible(action)">
          <span style="font-size: 2em">
            {{ totalPossible(action) }} contenants {{ action == 'wash' ? ' lavés' : 'intégrés' }}
          </span>
          <v-radio-group v-model="integrateToRestaurantStock" v-if="action=='wash'">
            <v-radio label="intégrer au stock du restaurant" :value="true"></v-radio>
            <v-radio label="laisser dans le point de dépôt" :value="false"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn @click="reset" :disabled="savingTransaction">Annuler</v-btn>
          <v-btn class="primary" @click="confirm" :disabled="nb <= 0 || savingTransaction">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import StocksTabItem from "@/components/StocksTabItem";
import { transactionsService } from "@/_services";
import { nowLoading, loadingComplete } from "@/_helpers";
import i18n from '@/i18n';

export default {
  props: {
    showDirty: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    action: '', // wash or pick
    showPopup: false,
    item: null,
    nb: 0,
    max: 0,
    integrateToRestaurantStock: false,
  }),

  components: {
    StocksTabItem
  },

  computed: {
    ...mapState({
      stocks: (state) => state.restaurants.stocks
    }),
    ...mapGetters({
      allFormats: "restaurants/allFormats",
      currentRestaurant: "restaurants/currentRestaurant",
      savingTransaction: "loading/savingTransaction",
    }),
  },

  methods: {
    ...mapActions({
      fetchCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),
    totalPossible(action) {
      if( this.stocks.length === 0 ) return 0;
      return this.stocks
        .map(item => action == 'wash' ? item.drop_off_point_dirty_containers_count : item.drop_off_point_containers_count)
        .reduce((sum, current) => current ? sum + current : sum)
    },

    setupAction(item, action) {
      this.action = action
      this.item = item
      this.max = action == 'wash' ? item.drop_off_point_dirty_containers_count : item.drop_off_point_containers_count
      this.nb = this.max
      this.showPopup = true
    },

    setupActionAll(action) {
      this.action = action
      this.showPopup = true
      this.nb = this.totalPossible(action)
    },

    reset() {
      this.showPopup = false
      this.nb = 0
      this.max = 0
      this.item = null
      this.action = ''
      this.integrateToRestaurantStock = false
    },

    confirm(){

      nowLoading("savingTransaction");

      let containers = [];
      if (this.item) {
        containers.push({
          account_format_id: this.item.id,
          quantity: this.nb,
        })
      } else {
        containers = this.stocks
          .filter(item => this.action == 'wash' ? item.drop_off_point_dirty_containers_count : item.drop_off_point_containers_count)
          .map(item => ({
            account_format_id: item.id,
            quantity: this.action == 'wash' ? item.drop_off_point_dirty_containers_count : item.drop_off_point_containers_count,
          }))
      }

      const transactionType = this.action + (this.integrateToRestaurantStock ? '_and_keep' : '');
      transactionsService
        .create(
          this.currentRestaurant.uuid,
          transactionType,
          { containers }
        )
        .then(() => {
          this.$store.dispatch(
            "alert/success",
            "La transaction a été créée avec succès"
          );
          this.fetchCurrentRestaurantStocks();
          this.reset();
        })
        .catch(error => {
          let message = error.response?.data?.errors.map(e => e.detail).join(', ');
          this.$store.dispatch(
            "alert/error",
            message || i18n.t("errors.something_went_wrong")
          );
        })
        .finally(() => {loadingComplete("savingTransaction")})
    }
  },
};
</script>

<style lang="scss">
.stock-table {
  width: 100%;
  border-spacing: 0;
  td {
    border-top: thin solid rgba(0, 0, 0, 0.12)
  }
}
@media (max-width: 445px) {
  .stock-table td button .btn-label {
    display: none
  }
}
</style>
