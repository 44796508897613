<template>
  <div>
    <div v-if="!noStreamApiSupport">
      <div v-if="loading">
        <p class="text-center pa-5">
          <v-progress-circular class="mb-3" indeterminate /><br />
          Démarrage de la caméra
        </p>
      </div>

      <qrcode-stream
        id="qr-reader-container"
        @init="onInit"
        @decode="onDecode"
        v-if="camera"
        :camera="recording"
      >
        <div v-if="validationSuccess" class="validation-success"></div>

        <div class="d-flex justify-center mb-6" v-else>
          <v-chip class="ma-2" color="teal lighten-5" label>
            <v-progress-circular
              class="mr-2"
              :size="20"
              :width="3"
              indeterminate
            />
            En attente du QR Code client
          </v-chip>
        </div>
      </qrcode-stream>
    </div>

    <div class="text-center ma-3" v-else>
      <p class="title">Impossible de démarrer la caméra</p>
      <div>
        <v-btn x-large color="primary" @click="openCodeInput">
          <v-icon class="mr-1"> mdi-numeric </v-icon>
          Saisir Code PIN
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { displayError } from "@/_helpers";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "QrCodeReader",
  data() {
    return {
      recording: "auto",
      request: undefined,
      transactionPin: null,
      isValid: false,
      loading: false,
      noStreamApiSupport: false
    };
  },

  props: {
    camera: Boolean
  },

  computed: {
    ...mapGetters({
      waitingRequest: "loading/waitingRequest",
      currentRestaurant: "restaurants/currentRestaurant"
    }),
    validationSuccess() {
      return this.isValid === true;
    }
  },
  methods: {
    ...mapActions({
      fetchRequestCustomer: "request/fetchCustomer"
    }),
    onDecode(qrcode) {
      try {
        const parsedQrCode = JSON.parse(qrcode);

        const schema = {
          pin: (value) => value.length == 7
        };

        const validate = (object, schema) =>
          Object.entries(schema)
            .map(([property, validate]) => [
              property,
              validate(object[property])
            ])
            .reduce((errors, [property, valid]) => {
              if (!valid) {
                errors.push(new Error(`${property} is invalid`));
              }

              return errors;
            }, []);

        const errors = validate(parsedQrCode, schema);

        if (errors.length > 0) {
          this.isValid = false;
          let error = [];
          for (const { message } of errors) {
            error.push(message);
          }
          error = error.join(", ");
          displayError(error);
        } else {
          this.isValid = true;
          this.transactionPin = parsedQrCode.pin;
          this.fetchRequestCustomer({
            uuid: this.currentRestaurant.uuid,
            transactionPin: this.transactionPin
          })
            .then(() => {
              this.turnRecordingOff();
              this.$emit("confirm-request-intent");
            })
            .catch(() => displayError("Code PIN client invalide"));
        }
      } catch (err) {
        this.isValid = false;
        displayError(err)
      }
    },
    turnCameraOn() {
      this.camera = true;
      this.loading = true;
    },
    turnCameraOff() {
      this.camera = false;
    },
    turnRecordingOn() {
      this.recording = "auto";
    },
    turnRecordingOff() {
      this.recording = "off";
    },
    async onInit(promise) {
      try {
        this.turnCameraOn();
        await promise;
      } catch (error) {
        this.noStreamApiSupport = true;

        if (error.name === "NotAllowedError") {
          displayError("ERROR: you need to grant camera access permisson");
        } else if (error.name === "NotFoundError") {
          displayError("ERROR: no camera on this device");
        } else if (error.name === "NotSupportedError") {
          displayError("ERROR: secure context required (HTTPS, localhost)");
        } else if (error.name === "NotReadableError") {
          displayError("ERROR: is the camera already in use?");
        } else if (error.name === "OverconstrainedError") {
          displayError("ERROR: installed cameras are not suitable");
        } else if (error.name === "StreamApiNotSupportedError") {
          displayError("ERROR: Stream API is not supported in this browser");
        } else {
          displayError("ERROR: something went wrong with the camera");
        }
      } finally {
        this.loading = false;
      }
    },

    cancelRequest() {
      this.$emit("turn-camera-off");
    },
    openCodeInput() {
      this.$emit("open-code-input");
    }
  },
  mounted() {
    this.turnCameraOn();
  }
};
</script>

<style scoped>
#qr-reader-container {
  width: 100vw;
}

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 10px;
}
.validation-success {
  color: var(--v-primary-base);
}
.validation-failure {
  color: var(--v-error-base);
}
</style>
