import { requestService } from "@/_services";

const state = {
  customer: null,
  deactivate_card: false,
  email: null,
  error: null,
  grabs: [],
  redeem_offer: false,
  refills: 0,
  returns: [],
  status: null,
  summary: {},
  transactionPin: null,
};

const actions = {
  fetchCustomer({ commit }, { transactionPin, uuid }) {
    return requestService.fetchCustomer(transactionPin, uuid).then(response => {
      commit("setCustomer", response);
      commit("setTransactionPin", transactionPin);
      commit("setRequestStatus", { status: "pending", summary: {} });
    });
  },
  refreshCustomer({ commit, state }, restaurantUuid) {
    return requestService
      .fetchCustomer(state.transactionPin, restaurantUuid)
      .then(response => {
        commit("setCustomer", response);
      });
  },
  checkEmail({ state, commit }, restaurantUuid) {
    return requestService
      .checkEmail(state.email, restaurantUuid)
      .then(() => {
        commit(
          "loading/updateStatus",
          { key: "checkingGuestEmail", value: false },
          { root: true }
        );
        return true;
      })
      .catch(() => {
        commit(
          "loading/updateStatus",
          { key: "checkingGuestEmail", value: false },
          { root: true }
        );
        return false;
      });
  },
  submit({ state, commit }, restaurantUuid) {
    let payload = {};
    if (state.transactionPin) payload["transaction_pin"] = state.transactionPin;
    else if (state.email) payload["email"] = state.email;
    else throw "email or transaction_pin is missing";

    if (state.refills && state.refills > 0) payload["refills"] = state.refills;

    if (state.grabs) {
      const filteredGrabs = _filterRequest(state.grabs);
      if (filteredGrabs) payload["grabs"] = filteredGrabs;
    }

    if (state.returns) {
      const filteredReturns = _filterRequest(state.returns);
      if (filteredReturns) payload["returns"] = filteredReturns;
    }

    if (state.redeem_offer) {
      payload["redeem_offer"] = state.redeem_offer;
    }

    if (state.deactivate_card) {
      payload["deactivate_card"] = state.deactivate_card;
    }

    commit(
      "loading/updateStatus",
      { key: "submittingRequest", value: true },
      { root: true }
    );

    return requestService
      .submitRequest(payload, restaurantUuid)
      .then(result => {
        commit(
          "loading/updateStatus",
          { key: "submittingRequest", value: false },
          { root: true }
        );
        commit("setRequestStatus", { status: "success", summary: result });
      })
      .catch(error => {
        commit(
          "loading/updateStatus",
          { key: "submittingRequest", value: false },
          { root: true }
        );
        commit("setRequestStatus", { status: "error", error: error });
      });
  },
  error({ commit }, message) {
    commit("error", message);
  },
  clear({ commit }, message) {
    commit("clear", message);
  }
};

const mutations = {
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setDeactivateCard(state, value) {
    state.deactivate_card = value;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setTransactionPin(state, transactionPin) {
    state.transactionPin = transactionPin;
  },
  setRefills(state, value) {
    state.refills = value;
  },
  setGrabs(state, value) {
    state.grabs = value;
  },
  setReturns(state, value) {
    state.returns = value;
  },
  setRedeemOffer(state, value) {
    state.redeem_offer = value;
  },
  setRequestStatus(state, { status, summary, error }) {
    state.status = status;
    state.error = error;
    state.summary = summary;
  },
  clear(state) {
    Object.keys(state).forEach(function(index) {
      state[index] = null;
    });

    state.summary = {};
    state.refills = 0;
    state.grabs = [];
    state.returns = [];
    state.redeem_offer = false;
  }
};

const getters = {
  availableFormats: state => {
    if (!state.customer) return [];

    return state.customer.containers
      .filter(format => {
        return format.containers_count > 0;
      })
      .map(format => {
        return {
          id: format.id,
          title: format.title,
          subtitle: format.subtitle,
          image_url: format.image_url,
          amount: format.amount,
          containers_count: format.containers_count
        };
      });
  },
  availableFormatsToReturn: (state, _getters, _rootState, rootGetters) => {
    if (!state.customer) return [];

    const hasMigratingContainers =
      rootGetters["request/hasMigratingContainers"];
    const migratingContainersCount =
      rootGetters["request/customerMigratingContainersCount"];

    return state.customer.containers
      .filter(format => {
        return format.containers_count > 0 || hasMigratingContainers;
      })
      .map(format => {
        return {
          id: format.id,
          title: format.title,
          subtitle: format.subtitle,
          image_url: format.image_url,
          amount: format.amount,
          containers_count: format.containers_count + migratingContainersCount
        };
      });
  },
  presummary: (state, _getters, _rootState, rootGetters) => {
    const allFormats = rootGetters["restaurants/allFormats"];
    const depositChange = rootGetters["restaurants/totalCost"];
    let returns = [];
    for (const [formatId, value] of Object.entries(state.returns)) {
      if (value > 0) {
        const format = allFormats.find(item => item.id == formatId);
        if (format) {
          returns.push({ quantity: value, title: format.title });
        }
      }
    }
    if (returns.length === 0) returns = null;

    let grabs = [];
    for (const [formatId, value] of Object.entries(state.grabs)) {
      if (value > 0) {
        const format = allFormats.find(item => item.id == formatId);
        if (format) {
          grabs.push({ quantity: value, title: format.title });
        }
      }
    }

    if (grabs.length === 0) grabs = null;

    let summary = {
      deposit_change: depositChange,
      refills: state.refills,
      returns: returns,
      grabs: grabs
    };

    return summary;
  },
  customerAvailableDeposit: state => {
    if (!state.customer) return 0;

    return state.customer.available_deposit;
  },
  customerAvailablePoints: state => {
    if (!state.customer) return 0;

    return state.customer.reward_points_count;
  },
  customerMigratingContainersCount: state => {
    if (!state.customer) return 0;

    return state.customer.migrating_format_containers_count;
  },
  totalCost: (state, _getters, _rootState, rootGetters) => {
    const allFormats = rootGetters["restaurants/allFormats"];

    let totalGrabsCost = 0;
    let totalReturnsCost = 0;

    if (state.grabs) {
      for (const [formatId, value] of Object.entries(state.grabs)) {
        if (value > 0) {
          const format = allFormats.find(item => item.id == formatId);
          if (format) {
            totalGrabsCost += format.amount * value;
          }
        }
      }
    }

    if (state.returns) {
      for (const [formatId, value] of Object.entries(state.returns)) {
        if (value > 0) {
          const format = allFormats.find(item => item.id == formatId);
          if (format) {
            totalReturnsCost += format.amount * value;
          }
        }
      }
    }

    return totalGrabsCost - totalReturnsCost;
  },
  hasEnoughDeposit: (state, getters, _rootState, rootGetters) => {
    const customerAvailableDeposit =
      rootGetters["request/customerAvailableDeposit"];

    if (getters.totalCost === 0) return true;

    return customerAvailableDeposit - getters.totalCost >= 0;
  },
  hasMigratingContainers: (state, getters, _rootState, rootGetters) => {
    const count = rootGetters["request/customerMigratingContainersCount"];

    return count > 0;
  },

  isCardHolder: state => {
    return state.customer && state.customer.type === "card_holder";
  },

  isPendingCardHolder: (state, getters) => {
    return getters.isCardHolder && state.customer.status == "pending";
  },

  isActiveCardHolder: (_, getters) => {
    return getters.isCardHolder && !getters.isPendingCardHolder;
  },

  accountCardMaxDeposit: state => {
    return state.customer.account_card_max_deposit;
  }
};

export const request = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

const _filterRequest = obj => {
  let filteredRequest = [];
  for (const [account_format_id, quantity] of Object.entries(obj)) {
    if (quantity > 0) {
      // account_format_id is a string :/
      filteredRequest.push({ account_format_id, quantity });
    }
  }

  if (Object.keys(filteredRequest).length > 0) return filteredRequest;

  return null;
};
