<template>
  <ValidationObserver ref="observerLoginForm" v-slot="{ invalid, validated, handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submit)">
      <v-card>
        <v-card-text>
          <div class="title">Informations</div>

          <ValidationProvider name="'Nom'" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              v-model="name"
              :error-messages="errors"
              :success="valid"
              label="Nom"
              :disabled="savingRestaurant"
            ></v-text-field>
          </ValidationProvider>

          <v-text-field
            v-model="website_url"
            label="Site web"
            :disabled="savingRestaurant"
            persistent-hint
            hint="Url du site web, page Facebook, Instagram, ..."
          ></v-text-field>

          <div>
            <table>
              <tr>
                <td v-show="avatarThumbnail" class='pr-2'>
                  <span>Logo</span><br>
                  <img :src="avatarThumbnail" height="150" />
                </td>
                <td v-show="avatarPreview" >
                  <span>Aperçu</span>
                  <br>
                  <img :src="avatarPreview" height="150" />
                </td>
              </tr>
            </table>
            <v-file-input
              label="Logo"
              v-model="avatar"
              show-size
              truncate-length="15"
              @change="onFileChange"
            ></v-file-input>
          </div>

          <v-text-field
            v-model="phone_1"
            label="Téléphone"
            :disabled="savingRestaurant"
          ></v-text-field>

          <v-text-field
            v-model="cooking_styles"
            label="Cuisine (japonais, bio...)"
            :disabled="savingRestaurant"
          ></v-text-field>

          <v-text-field
            v-model="address_1"
            label="Adresse"
            :disabled="savingRestaurant"
          ></v-text-field>

          <v-text-field
            v-model="address_2"
            label="Complément d'adresse"
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="3" class="py-0">
              <v-text-field
                v-model="zipcode"
                label="Code Postal"
                :disabled="savingRestaurant"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="9" class="py-0">
              <v-text-field
                v-model="city"
                label="Ville"
                :disabled="savingRestaurant"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="country"
            label="Pays"
            :disabled="savingRestaurant"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="$emit('cancel')"
            :disabled="savingRestaurant"
            :loading="savingRestaurant"
          >
            Annuler
          </v-btn>

          <v-btn
            depressed
            type="submit"
            color="primary"
            :disabled="savingRestaurant || invalid"
            :loading="savingRestaurant"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue'

import { mapActions, mapState } from "vuex";

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    restaurant: Object,
  },

  mounted() {
    this.assignPropToData()
  },

  data: () => ({
    tab: "infos",
    uuid: null,
    avatar: null,
    name: null,
    website_url: null,
    address_1: null,
    address_2: null,
    zipcode: null,
    city: null,
    country: null,
    phone_1: null,
    avatar_url: null,
    cooking_styles: null,
  }),

  computed: {
    ...mapState({
      savingRestaurant: "loading/savingRestaurant",
    }),
    avatarThumbnail() {
      return this.avatar_url && this.avatar_url['300x300']
    },
    avatarPreview() {
      return this.avatar_url && this.avatar_url['preview']
    },
  },

  methods: {
    ...mapActions({
      updateRestaurant: "restaurants/update",
    }),

    onFileChange(file) {
      const url = file === undefined ? null : URL.createObjectURL(file)
      Vue.set(this.avatar_url, 'preview', url)
    },
    submit() {
      let payload = {
        avatar: this.avatar,
        name: this.name,
        website_url: this.website_url,
        phone_1: this.phone_1,
        cooking_styles: this.cooking_styles,
        address_1: this.address_1,
        address_2: this.address_2,
        zipcode: this.zipcode,
        city: this.city,
        country: this.country,
      };

      let formData = new FormData();
      for (var key in payload) {
        formData.append(key, payload[key]);
      }

      this.updateRestaurant({ uuid: this.uuid, data: formData })
        .then(() => this.$emit('close'))
    },
    assignPropToData() {
      Object.assign(this._data, this.restaurant);
    }
  },
  watch: {
    restaurant () {
      this.assignPropToData()
    }
  }
};
</script>
