import { axiosFetcher } from '@/_helpers';

export const invoicesService = {
  index
};

function index(uuid) {
  return axiosFetcher(`/${uuid}/invoices`)
    .then(response => {
      return response
    })
}
