import { axiosFetcher } from "@/_helpers";

export const requestService = {
  fetchCustomer,
  checkEmail,
  submitRequest
};

function fetchCustomer(transactionPin, uuid) {
  let url = `/${uuid}/customers/${transactionPin}`;

  return axiosFetcher(url)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error;
    });
}

function checkEmail(email, restaurantUuid) {
  const requestOptions = {
    method: "POST",
    data: { email: email }
  };

  let url = `/${restaurantUuid}/requests/check_email`;

  return axiosFetcher(url, requestOptions)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
}

function submitRequest(payload, restaurantUuid) {
  const requestOptions = {
    method: "POST",
    data: payload
  };

  let url = `/${restaurantUuid}/requests/submit`;

  return axiosFetcher(url, requestOptions)
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
}
