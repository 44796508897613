<template>
  <div class="pt-4 text-center">
    <p>Entrez le Code PIN client</p>
    <code-input class="mb-5" v-model="transactionPin" />

    <v-btn
      color="primary"
      @click="confirmPin"
      :disabled="!transactionPinValid"
      class="btn-action"
    >
      Chercher
    </v-btn>
  </div>
</template>

<script>
import { displayError } from "@/_helpers";
import { mapActions, mapGetters } from "vuex";

import CodeInput from "@/components/request/CodeInput.vue";

export default {
  name: "CodeInputer",

  components: {
    CodeInput
  },

  data() {
    return {
      transactionPin: ""
    };
  },

  computed: {
    ...mapGetters({
      waitingRequest: "loading/waitingRequest",
      currentRestaurant: "restaurants/currentRestaurant"
    }),
    transactionPinValid() {
      return this.transactionPin.length === 7;
    }
  },

  methods: {
    ...mapActions({
      fetchRequestCustomer: "request/fetchCustomer"
    }),
    confirmPin() {
      this.fetchRequestCustomer({
        uuid: this.currentRestaurant.uuid,
        transactionPin: this.transactionPin
      })
        .then(() => this.$emit("confirm-request-intent"))
        .catch(() => displayError("Code PIN client invalide"));
    }
  }
};
</script>

<style scoped lang='scss'>
@import "~vuetify/src/styles/settings/_colors";

.result {
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.btn-action {
  width: calc(33% - 5px);
  height: 72px !important;
}

.divider-text {
  display: flex;
  align-items: center;
  text-align: center;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid map-get($grey, "lighten-2");
  }
  &::before {
    margin-right: 1em;
  }
  &::after {
    margin-left: 1em;
  }
}
</style>
