/**
 * Creates a querystring to use as payload in "application/x-www-form-urlencoded" requests
 *
 * This method does not support complex data as sub-objects or arrays
 *
 * @param {object} object - Object to use as payload
 * @return {URLSearchParams} Payload to use in requests
 */
export function wwwFormParams (object) {
  const params = new URLSearchParams()
  Object.keys(object).map(key => params.set(key, object[key]))

  return params
}
