import { axiosFetcher, wwwFormParams } from '@/_helpers';

export const authService = {
  logout,
  forgotPassword,
  resetPassword,
  refreshToken,
  exchangeCodeForToken,
  appInfo,
};

function exchangeCodeForToken(code) {

  return axiosFetcher(`/oauth/token`, {
    baseURL: process.env.VUE_APP_LOOPEAT_BASE_URL,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: wwwFormParams({
      client_id: process.env.VUE_APP_AUTH_USERNAME,
      redirect_uri: `${process.env.VUE_APP_BASE_URL}/auth/callback`,
      code,
      grant_type: 'authorization_code',
    }),
  })

}

function appInfo() {
  return axiosFetcher(`${process.env.VUE_APP_LOOPEAT_BASE_URL}/oauth/authorized_applications.json`)
    .then(response => {
      return response.find(app => app.uid === process.env.VUE_APP_AUTH_USERNAME)
    })
}

function forgotPassword(email) {
  const requestOptions = {
    method: 'POST',
    data: {
      email,
      client_id: process.env.VUE_APP_AUTH_USERNAME,
    },
  };

  return axiosFetcher('/auth/forgot_password', requestOptions)
    .then(function () {
      return true;
    })
}

function resetPassword(token, password) {
  const requestOptions = {
    method: 'POST',
    data: {
      token,
      password,
      client_id: process.env.VUE_APP_AUTH_USERNAME,
    },
  };

  return axiosFetcher('/auth/reset_password', requestOptions)
    .then(function () {
      return true;
    })
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

function refreshToken(token) {
  return axiosFetcher('/oauth/token', {
    baseURL: process.env.VUE_APP_LOOPEAT_BASE_URL,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: wwwFormParams({
      client_id: process.env.VUE_APP_AUTH_USERNAME,
      grant_type: 'refresh_token',
      refresh_token: token,
    }),
  })
}
