import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './modules/alert';
import { auth } from './modules/auth';
import { invoices } from './modules/invoices';
import { loading } from './modules/loading';
import { online } from './modules/online';
import { request } from './modules/request';
import { restaurants } from './modules/restaurants';
import { transactions } from './modules/transactions';
import { user } from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    auth,
    invoices,
    loading,
    online,
    request,
    restaurants,
    transactions,
    user,
  },
});
