<template>
  <v-snackbar
    v-model="showAlert"
    @input='onAlertHidden'
    top
    outlined
    :timeout="3000"
    :color='alertType'
    multi-line>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="hideAlert"
        class="pa-0 ma-0 btn-close-alert"
      >
        ×
      </v-btn>
    </template>
    {{ alertMessage }}
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AlertSnackbar',

  data: () => ({
    showAlert: false,
    alert: null
    //
  }),

  computed: {
    ...mapState({
      alerts: state => state.alert.data,
    }),
    alertType() {
      return this.alert && this.alert.type
    },
    alertMessage() {
      return this.alert && this.alert.message
    },
  },

  methods: {
    hideAlert() {
      this.showAlert = false
    },
    onAlertHidden() { },
  },

  watch: {
    alerts: function (value) {
      this.showAlert = false

      if (value.length > 0) {
        this.alert = this.alerts.pop()
        setTimeout(() => { this.showAlert = true }, 100)
      }
    },
  },
}
</script>

<style lang="scss">
  .btn-close-alert {
    position: absolute !important;
    top: 10px;
    right: 10px;
    min-width: 20px !important;
    width: 20px !important;
    min-height: 20px !important;
    height: 20px !important;
  }
</style>
