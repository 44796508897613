<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }} icon</title>
    <g :fill="iconColor">
      <circle cx="6.79" cy="1.85" r="1.85"/>
      <path d="M17.46,14.63a1.66,1.66,0,0,0,.39-1.06,1.69,1.69,0,1,0-3.37,0,1.66,1.66,0,0,0,.39,1.06H5v1.6a5.27,5.27,0,0,0,5.27,5.27h3.46A5.27,5.27,0,0,0,19,16.23v-1.6Zm.23,1.6a4,4,0,0,1-4,4H10.27a4,4,0,0,1-4-4v-.29H17.69Z" transform="translate(-5 -2.5)"/>
      <polygon points="8.37 5.36 10.9 4.9 10.44 7.43 8.37 5.36"/>
      <rect id="Rectangle" x="7.86" y="8.53" width="4.18" height="4.18" transform="translate(-9.6 7.64) rotate(-45)"/>
    </g>
  </svg>
</template>

<script>
export default {
  data: () => ({
    iconName: 'Refill'
  }),

  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>