<template>
  <div>
    <v-card elevation="0" v-if="!hasEnoughDeposit">
      <v-card-text>
        <v-alert class="text-center" dense outlined text>
          <div v-if="isCardHolder">
            <p class="red--text">
              {{ $t("request.not_enough_deposit_card") }}
            </p>
            <p>
              {{ $t("request.ask_to_buy_card") }}
            </p>
          </div>
          <div v-else>
            <p class="red--text">
              {{ notEnoughDepositText }}
            </p>
            <p>
              {{ $t("request.ask_to_add_deposit") }}
            </p>
            <v-btn color="primary" small @click="refreshRequestCustomer">
              <v-icon left>mdi-reload</v-icon> Actualiser
            </v-btn>
          </div>
        </v-alert>
      </v-card-text>
    </v-card>

    <v-card elevation="0" v-else-if="totalCost">
      <v-card-text>
        <v-alert dense outlined text border="left" icon="mdi-cash" type="info">
          {{ totalCostText }}
        </v-alert>
      </v-card-text>
    </v-card>

    <div v-if="initialized" class="pb-16">
      <v-expansion-panels v-model="panel" tile :disabled="submittingRequest">
        <v-expansion-panel v-if="acceptGrabAndReturn && !isPendingCardHolder">
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <return-icon :width="24" :height="24" class="mr-4"></return-icon>
              {{ $t(`request.returns_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <format-count-selector :value="returns" :availableFormats="customerAvailableFormatsToReturn"
              :action="'returns'" :disabled="submittingRequest" @update-format-count="updateFormatCount($event)">
              <template v-slot:alert v-if="noReuseLoop">
                {{ $t(`request.restaurant_no_loop`) }}
              </template>
              <template v-slot:alert v-else-if="customerNoContainerToReturn">
                {{ $t(`request.customer_no_container`) }}
              </template>
            </format-count-selector>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="acceptGrabAndReturn">
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <borrow-icon :width="24" :height="24" class="mr-4"></borrow-icon>
              {{ $t(`request.grabs_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <format-count-selector :value="grabs" :availableFormats="restaurantAvailableFormats" :action="'grabs'"
              :disabled="submittingRequest" @update-format-count="updateFormatCount($event)">
              <template v-slot:alert v-if="noReuseLoop">
                {{ $t(`request.restaurant_no_loop`) }}
              </template>
              <template v-slot:alert v-else-if="restaurantNoContainer">
                {{ $t(`request.restaurant_no_container`) }}
              </template>
            </format-count-selector>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <refill-icon :width="24" :height="24" class="mr-4"></refill-icon>
              {{ $t(`request.refills_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-center">
              <counter :value="refills" :maxValue="10" @update-count="updateRefillCount($event)"
                :disabled="submittingRequest"></counter>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!isCardHolder">
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <offer-icon :width="24" :height="24" class="mr-4"></offer-icon>
              {{ $t(`request.redeem_offer_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <table class="w-100">
              <tr v-if="hasOffer">
                <td style="vertical-align: middle">
                  <p>{{ offerDescription }}</p>

                  <v-switch v-model="redeem_offer" :label="$t('transactions.activate_reward')"
                    v-if="customerEnoughPoint" @change="updateRedeemOffer($event)"></v-switch>

                  <v-alert v-else outlined text type="success" class="mb-0">
                    {{ $t(`request.customer_not_enough_point`) }}
                  </v-alert>
                </td>
              </tr>
              <tr v-else>
                <td style="vertical-align: middle">
                  <v-alert outlined text type="success" class="mb-0">
                    {{ $t(`request.restaurant_no_offer`) }}
                  </v-alert>
                </td>
              </tr>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="isActiveCardHolder">
          <v-expansion-panel-header>
            <div class="v-expansion-panel-header__text-with-icon">
              <v-icon color="black" width="24" height="24" class="mr-4">mdi mdi-cards</v-icon>
              {{ $t(`request.deactivate_card_action`) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container">
              <v-alert v-if="hasContainersAtHome" outlined text type="warning" class="mb-8">
                {{ $t('request.card_remaining_containers') }}
              </v-alert>

              <v-switch v-model="deactivate_card" :label="$t('transactions.deactivate_card')"
                @change="updateDeactivateCard($event)">
              </v-switch>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="position-bottom-fixed">
        <v-divider></v-divider>
        <div class="d-flex justify-space-around py-2 white">
          <v-btn rounded outlined large @click="cancel" :disabled="submittingRequest">
            {{ $t("shared.cancel") }}
          </v-btn>

          <v-btn rounded color="primary" large @click="submit" class="px-8" :disabled="submitDisabled"
            :loading="submittingRequest">
            {{ submitBtnText }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-card v-else>
      <v-card-text class="text-center">
        <v-progress-circular indeterminate> </v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import BorrowIcon from "@/components/icons/BorrowIcon.vue";
import OfferIcon from "@/components/icons/OfferIcon.vue";
import RefillIcon from "@/components/icons/RefillIcon.vue";
import ReturnIcon from "@/components/icons/ReturnIcon.vue";

import Counter from "./Counter.vue";
import FormatCountSelector from "./FormatCountSelector.vue";

export default {
  components: {
    Counter,
    FormatCountSelector,
    BorrowIcon,
    OfferIcon,
    RefillIcon,
    ReturnIcon
  },
  data() {
    return {
      redeem_offer: false,
      deactivate_card: false,
      grabs: {},
      returns: {},
      refills: 0,
      initialized: false,
      panel: 0
    };
  },

  mounted() {
    this.initRequest();
  },

  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant",
      restaurantAvailableFormats: "restaurants/availableFormats",
      customerAvailableFormats: "request/availableFormats",
      customerAvailableFormatsToReturn: "request/availableFormatsToReturn",
      customerAvailableDeposit: "request/customerAvailableDeposit",
      customerAvailablePoints: "request/customerAvailablePoints",
      customerMigratingContainersCount:
        "request/customerMigratingContainersCount",
      totalCost: "request/totalCost",
      hasEnoughDeposit: "request/hasEnoughDeposit",
      hasMigratingContainers: "request/hasMigratingContainers",
      submittingRequest: "loading/submittingRequest",
      isCardHolder: "request/isCardHolder",
      isPendingCardHolder: "request/isPendingCardHolder",
      isActiveCardHolder: "request/isActiveCardHolder",
      accountCardMaxDeposit: "request/accountCardMaxDeposit"
    }),

    acceptGrabAndReturn() {
      return this.currentRestaurant.reuse_loop_level === "grab_return_refill";
    },
    offerDescription() {
      return this.currentRestaurant.reward_description;
    },
    hasOffer() {
      return this.offerDescription !== null && this.offerDescription !== "";
    },
    restaurantNoContainer() {
      return !this.restaurantAvailableFormats.length;
    },
    customerNoContainer() {
      return !this.customerAvailableFormats.length;
    },
    customerContainerToReturn() {
      return this.customerAvailableFormatsToReturn.length;
    },
    customerNoContainerToReturn() {
      return !this.customerContainerToReturn;
    },
    customerNoDeposit() {
      return !this.customerAvailableDeposit;
    },
    customerEnoughPoint() {
      return this.customerAvailablePoints >= 10;
    },
    noReuseLoop() {
      return this.currentRestaurant.reuse_loop_level !== "grab_return_refill";
    },

    totalCostText() {
      let key = "nothing_to_do";
      const cardSuffix = this.isCardHolder ? "_card" : "";

      const value = this.isPendingCardHolder
        ? this.accountCardMaxDeposit
        : Math.abs(this.totalCost);

      const value_with_currency = `${value} €`;

      if (this.isPendingCardHolder) {
        key = "ask_to_activate_card";
      } else if (this.totalCost > 0) {
        key = "total_cost_to_take" + cardSuffix;
      } else if (this.totalCost < 0) {
        key = "total_cost_to_refund" + cardSuffix;
      }

      return this.$tc(`request.${key}`, value, {
        amount: value_with_currency
      });
    },
    notEnoughDepositText() {
      const value = Math.abs(this.customerAvailableDeposit - this.totalCost),
        value_with_currency = `${value} €`;

      return this.$tc(`request.not_enough_deposit`, value, {
        amount: value_with_currency
      });
    },
    submitDisabled() {
      return (
        !this.validRequest || !this.hasEnoughDeposit || this.submittingRequest
      );
    },
    validRequest() {
      let isValid = false;

      if (this.hasOffer) isValid = isValid || this.redeem_offer;

      if (this.deactivate_card) {
        return true;
      }

      isValid = isValid || this.refills > 0;

      if (!this.acceptGrabAndReturn) return isValid;

      isValid = isValid || Object.values(this.grabs).some(value => value > 0);
      isValid = isValid || Object.values(this.returns).some(value => value > 0);

      return isValid;
    },

    submitBtnText() {
      return this.isPendingCardHolder
        ? this.$tc(
            "request.submit_activation_card",
            this.accountCardMaxDeposit,
            {
              amount: this.accountCardMaxDeposit
            }
          )
        : this.$t("shared.confirm");
    },

    hasContainersAtHome() {
      const hasNoReturns = this.totalCost >= 0;

      return this.customerContainerToReturn && hasNoReturns;
    },
  },

  methods: {
    ...mapActions({
      submitRequest: "request/submit",
      refreshCustomer: "request/refreshCustomer",
      refreshCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),
    ...mapMutations({
      setDeactivateCard: "request/setDeactivateCard",
      setGrabs: "request/setGrabs",
      setRefills: "request/setRefills",
      setRedeemOffer: "request/setRedeemOffer",
      setReturns: "request/setReturns",
    }),
    initRequest() {
      this.redeem_offer = false;
      this.refills = 0;
      this.grabs = this.restaurantAvailableFormats.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: 0 }),
        {}
      );
      this.returns = this.customerAvailableFormatsToReturn.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: 0 }),
        {}
      );

      this.initialized = true;
    },
    refreshRequestCustomer() {
      this.refreshCustomer(this.currentRestaurant.uuid);
    },
    submit() {
      this.submitRequest(this.currentRestaurant.uuid).then(() =>
        this.refreshCurrentRestaurantStocks()
      );
    },
    cancel() {
      this.$emit("cancel-request");
    },
    updateRefillCount(value) {
      this.refills = value;

      this.setRefills(this.refills);
    },
    updateDeactivateCard() {
      this.setDeactivateCard(this.deactivate_card);
    },
    updateFormatCount({ value, formatId, action }) {
      if (action === "returns") {
        this.returns[formatId] = value;
        this.setReturns(this.returns);
      } else if (action === "grabs") {
        this.grabs[formatId] = value;
        this.setGrabs(this.grabs);
      }
    },
    updateRedeemOffer() {
      this.setRedeemOffer(this.redeem_offer);
    }
  }
};
</script>

<style>
.v-expansion-panel-header__text-with-icon {
  line-height: 28px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;
}
</style>
