export function formatDate(date, format) {
  const map = {
    hh: `0${date.getHours()}`.slice(-2),
    mm: `0${date.getMinutes()}`.slice(-2),
    MM: `0${date.getMonth() + 1}`.slice(-2),
    DD: `0${date.getDate()}`.slice(-2),
    YY: date.getFullYear().toString().slice(-2),
    YYYY: date.getFullYear()
  }

  return format.replace(/MM|DD|YYYY|YY|hh|mm/g, matched => map[matched])
}
