<template>
  <v-card class="mt-0 mt-sm-4 px-2 px-sm-0">
    <v-toolbar flat>
      <v-toolbar-title>
        Factures
      </v-toolbar-title>
    </v-toolbar>
    <div v-if="retrievingInvoices" class="text-center">
      <v-card-text>
        <v-progress-circular indeterminate />
      </v-card-text>
    </div>

    <div v-else>
      <v-list v-if="anyInvoices" three-line>
        <template v-for="(invoice, index) in invoices">
          <v-list-item class="px-2 px-sm-4" :key="index">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark>
                {{ statusToIcon(invoice.status) }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                >Facture {{ invoice.number }}</v-list-item-title
              >
              <v-list-item-subtitle class="text--primary">{{
                amountWithCurrency(invoice.amount_due, invoice.currency)
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                formatDate(invoice.created, "DD-MM-YYYY")
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                v-if="invoice.invoice_pdf"
                :href="invoice.invoice_pdf"
                target="_blank"
                icon
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-card-text v-else>
        <v-alert dense outlined type="info">
          Aucune facture trouvée
        </v-alert>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { formatDate } from "@/_helpers";

export default {
  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant",
      retrievingInvoices: "loading/retrievingInvoices"
    }),
    ...mapState({
      invoices: state => state.invoices.invoices
    }),
    anyInvoices() {
      return this.invoices.length > 0;
    }
  },
  mounted() {
    const uuid = this.currentRestaurant.uuid;
    this.fetchInvoices(uuid);
  },
  methods: {
    ...mapActions({
      fetchInvoices: "invoices/index"
    }),
    statusToIcon(value) {
      if (value == "paid") return "mdi-check";
      if (value == "open") return "mdi-clock-outline";

      return "mdi-clock-outline";
    },
    formatDate(dateStr, format) {
      const date = new Date(dateStr * 1000);
      return formatDate(date, format);
    },
    amountWithCurrency(amount, currency) {
      if (currency === "eur") return `${amount} €`;

      return `€${amount}`;
    }
  }
};
</script>
