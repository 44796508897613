<template>
  <v-btn
    icon
    class="ma-0"
    @click="$emit('clicked')">
    <v-icon>mdi-arrow-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BackButton'
}
</script>
