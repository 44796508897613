import { axiosFetcher } from '@/_helpers';

export const transactionsService = {
  index,
  create,
};

function index(uuid) {
  return axiosFetcher(`/${uuid}/transactions`)
    .then((response) => {
     return response;
    }).catch(error => {
      throw(error);
    });
}

function create(uuid, type, transaction) {
  return axiosFetcher(`/${uuid}/transactions/${type}_drop_off_point`, {
    method: "POST",
    data: { transaction }
  });
}
