import { invoicesService } from '@/_services';

const state = { invoices: [] };

const actions = {
  index({ commit }, restaurant_uuid) {
    commit('loading/updateStatus', { key: 'retrievingInvoices', value: true }, { root: true })

    invoicesService.index(restaurant_uuid)
      .then((invoices) => {
        commit('retrieveInvoicesSuccess', invoices),
        (error) => commit('retrieveInvoicesFailure', error)
      }).finally(() => {
        commit('loading/updateStatus', { key: 'retrievingInvoices', value: false }, { root: true })
      })
  },
};

const getters = {}

const mutations = {
  retrieveInvoicesSuccess(state, invoices) {
    // state.loading = false;
    state.invoices = invoices;
  },
  retrieveInvoicesFailure(state) {
    state.invoices = [];
  }
};

export const invoices = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
