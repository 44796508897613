<template>
  <div>
    <form method="POST" :action="action" ref="authForm" v-if="currentRestaurant">
      <input v-for="(value, key) in $route.query" type="hidden" :name="key" :value="value" :key="key" />

      <input type="hidden" name="access_token" :value="accessToken" />
      <input type="hidden" name="restaurant_id" :value="currentRestaurant.uuid" />
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      userLoggedIn: "auth/loggedIn",
      accessToken: "auth/accessToken",
      currentRestaurant: "restaurants/currentRestaurant",
      loadingRestaurant: "loading/retrievingRestaurants",
    }),
    action: () => `${process.env.VUE_APP_LOOPEAT_BASE_URL}/api/v1/restaurant/auth/authorize`,
  },

  async mounted(){
    if (this.userLoggedIn) {
      // wait for restaurant loading end if needed
      if( this.loadingRestaurant ) await new Promise(resolve => {
        this.$watch('loadingRestaurant', resolve);
      })
      // transparently forward request to OAuth server without user interaction
      this.forwardToOAuth();
    } else {
      // User has to log in. Remember data for future use
      if (this.$route.query) this.rememberThirdPartyRequest(this.$route.query);
      this.$router.push({name: 'Home'});
    }
  },

  methods: {
    forwardToOAuth() {
      // transparently forward request to OAuth server without further user interaction
      this.$refs.authForm.submit();
    },
    ...mapActions({
      rememberThirdPartyRequest: "auth/rememberThirdPartyRequest"
    }),
  }
}
</script>
