<template>
  <div>
    <v-toolbar class="mb-4 elevation-2">
      <v-toolbar-title>
        <back-button @clicked="back" v-show="showBack" />
        {{ currentTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <v-window
      :value="tab"
      :touchless='true'>
      <v-window-item :value="tabs.ROOT" class="px-2">
        <v-card
          tile
          class='mb-2'>
          <v-card-title>
            <span class='text-h5'>Détails de mon compte</span>
          </v-card-title>

          <v-card-text
            class='pa-0'>
            <v-list
              class='pa-0'>
              <template v-for="(item, index) in items">
                <v-divider
                  v-if="item.divider"
                  :key="index"
                  :inset="item.inset" />

                <v-list-item
                  v-else
                  :key="item.title"
                  @click="goto(item.tab)">

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1">{{item.icon}}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card
          tile
          class='mb-2'>
          <v-card-text
            class='pa-0'>
            <v-list
              class='pa-0'>
              <v-list-item
                @click="requestLogout">
                <v-list-item-content>
                  <v-list-item-title>Se déconnecter</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-logout</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item
        v-if="tab == tabs.UPDATE_PASSWORD"
        :value="tabs.UPDATE_PASSWORD">
        <edit-password @cancel='back' />
      </v-window-item>
    </v-window>

    <confirm-logout-dialog ref='confirmLogout'></confirm-logout-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import BackButton from '@/components/BackButton.vue';
  import ConfirmLogoutDialog from "@/components/ConfirmDialog.vue";
  import EditPassword from '@/views/private/settings/EditPassword.vue';

  export default {
    data: () => ({
      tabs: {
        ROOT: 'root',
        UPDATE_PASSWORD: 'update-password'
      },
    }),

    props: {
      tab: String,
    },

    computed: {
      items() {
        return [{
          icon: 'mdi-lock',
          title: 'Changer mot de passe',
          tab: this.tabs.UPDATE_PASSWORD
        },
      ]},
      currentTitle() {
        switch (this.tab) {
          case this.tabs.UPDATE_PASSWORD:
            return "Changer mon mot de passe";
          default:
            return "Paramètres";
        }
      },
      showBack() {
        switch (this.tab) {
          case this.tabs.UPDATE_PASSWORD:
            return  true;
          default:
            return false;
        }
      },
    },
    mounted() {
      if (typeof this.tab === "undefined") {
        this.goto(this.tabs.ROOT)
      }
      else {
        this.goto(this.tab);
      }
    },
    components: {
      BackButton,
      ConfirmLogoutDialog,
      EditPassword,
    },
    methods: {
      ...mapActions({
        logout: 'auth/logout'
      }),
      back() {
        this.goto(this.tabs.ROOT);
      },
      goto(destinationTab) {
        const path = destinationTab !== this.tabs.ROOT ? `/settings/${destinationTab}` : `/settings`;

        if (this.$route.path !== path)
          this.$router.push(path);
      },
      async requestLogout() {
        if (
          await this.$refs.confirmLogout.open({
            message: "Etes-vous sur de vouloir vous déconnecter ?"
          })
        ) {
          this.logout()
        }
      },

    },
    watch: {
      $route (to){
        if (to.path === `/settings` && this.tab !== this.tabs.ROOT)
          this.goto(this.tabs.ROOT)
      }
    }
  }
</script>
