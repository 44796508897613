function jwtDecode(t) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
  }

export function isTokenExpired(encodedToken) {
  const decodedToken = jwtDecode(encodedToken)
  const exp = decodedToken.payload.exp

  return Date.now() >= exp * 1000
}
