<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <div class="d-block text-center mb-4">
            <img src="/img/logo.png" style="max-width:200px" />
            <br />
            <span class="title" style="line-height:1.2em !important"
              >pour les restaurateurs</span
            >
          </div>

          <router-view />

          <app-footer />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AppFooter from "./Footer";

export default {
  components: {
    AppFooter
  }
};
</script>
