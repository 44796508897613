<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile elevation="0">
      <v-toolbar flat>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-if="step === steps.IDENTIFY_CUSTOMER">
        <code-inputer
          v-if="usePin"
          @confirm-request-intent="requestIntentConfirmed"
          @close="close"
        />

        <qr-code-reader
          v-else-if="useQrCode"
          :camera="useQrCode"
          @confirm-request-intent="requestIntentConfirmed"
          @turn-camera-off="close"
          @open-code-input="source = 'pin'"
        />
      </div>

      <div v-if="step === steps.SELECT_FORMATS">
        <counts-selector @cancel-request="cancel"></counts-selector>
      </div>

      <div v-if="step === steps.SUCCESS">
        <v-card-text>
          <success-summary v-if="steps.SUCCESS" @close="close" />
        </v-card-text>
      </div>

      <div v-if="step === steps.ERROR">
        <v-card-text> </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import QrCodeReader from "@/components/request/QrCodeReader.vue";
import CodeInputer from "@/components/request/CodeInputer.vue";
import CountsSelector from "@/components/request/CountsSelector.vue";
import SuccessSummary from "@/components/request/SuccessSummary.vue";

const initialData = () => ({
  source: null,
  step: "identify-customer"
});

export default {
  props: {
    inputSource: {
      type: String,
      default: () => null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    QrCodeReader,
    CodeInputer,
    CountsSelector,
    SuccessSummary
  },

  data: function() {
    return Object.assign(
      {
        steps: {
          IDENTIFY_CUSTOMER: "identify-customer",
          SELECT_FORMATS: "select-formats",
          CONFIRM: "confirm-request",
          SUCCESS: "request-success",
          ERROR: "request-error"
        }
      },
      initialData()
    );
  },

  methods: {
    ...mapActions({
      clearRequest: "request/clear"
    }),

    close() {
      this.closeValidator();
    },

    cancel() {
      this.closeValidator();
    },

    requestIntentConfirmed() {
      this.step = this.steps.SELECT_FORMATS;
    },

    closeValidator() {
      this.clearRequest();

      const data = initialData();
      Object.keys(data).forEach(k => (this[k] = data[k]));

      this.$emit("close");
    }
  },

  computed: {
    ...mapGetters({
      currentRestaurant: "restaurants/currentRestaurant"
    }),
    ...mapState({
      requestStatus: state => state.request.status,
      requestError: state => state.request.error
    }),
    usePin() {
      return this.source === "pin";
    },
    useQrCode() {
      return this.source === "webcam";
    }
  },

  watch: {
    inputSource(value) {
      this.source = value;
    },
    requestStatus(status) {
      if (this.value)
        if (status == "success") {
          this.step = this.steps.SUCCESS;
        } else if (status == "error") {
          this.step = this.steps.ERROR;
        }
    }
  }
};
</script>
