<template>
  <div class="pill-counter">
    <v-btn fab small depressed @click="decrement" color="primary"> - </v-btn>
    <span class="count-value">{{ value }}</span>
    <v-btn fab small depressed @click="increment" color="primary"> + </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 99
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    decrement() {
      if (this.disabled) return;

      let newValue = this.value;
      newValue = newValue - 1;

      if (newValue >= 0) this.$emit("update-count", newValue);
    },
    increment() {
      if (this.disabled) return;

      let newValue = this.value;
      newValue = newValue + 1;

      if (newValue <= this.maxValue) this.$emit("update-count", newValue);
    }
  }
};
</script>
