<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row
          justify="center">
          <v-col
            :class='[containerClass]'>

            <router-view />

          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <app-bottom-nav v-if="userLoggedIn" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AppBottomNav from './BottomNav'

  const xsOnlyContainerClass = 'pa-0 col-sm-8 col-md-8 col-12';
  const fullScreenContainerClass = 'pa-0';

  export default {
    data: () => ({
      containerClass: xsOnlyContainerClass,
    }),
    
    components: {
      AppBottomNav
    },

    mounted() {
      if (this.$route.meta.fullscreen) {
        this.containerClass = fullScreenContainerClass;
      }
    },

    computed: {
      ...mapGetters({
        userLoggedIn: "auth/loggedIn",
      }),
    },

    watch: {
      $route (to) {
        this.containerClass = to.meta.fullscreen ? fullScreenContainerClass : xsOnlyContainerClass;
      }
    }
  }
</script>
