<template>
  <v-bottom-navigation v-model="activeBottomNav" app fixed>
    <v-btn :to="'/home'" value="home">
      <span>Accueil</span>
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn :to="'/transactions'" value="transactions">
      <span>Transactions</span>
      <v-icon>mdi-history</v-icon>
    </v-btn>

    <v-btn :to="'/invoices'" value="invoices">
      <span>Factures</span>
      <v-icon>mdi-receipt</v-icon>
    </v-btn>

    <v-btn :to="'/settings'" value="settings">
      <span>Paramètres</span>
      <v-icon>mdi-cogs</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    activeBottomNav: 'home',
  }),
}
</script>