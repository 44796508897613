<template>
  <div>
    <v-card
      v-if="form == 'login'"
      elevation="0">
      <ValidationObserver ref="observerLoginForm" v-slot="{ invalid, validated, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submit)"
          id="form--login-user">

          <v-card-text>
            <p class="text-h5">Identifiez-vous</p>
            <ValidationProvider name="'adresse email'" rules="required|email" v-slot="{ errors, valid }">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="email"
                name="email"
                :error-messages="errors"
                :success="valid"
                label="Adresse email"
                id="field--user-email"
                :disabled="loggingInOrRetrievingUser" />
            </ValidationProvider>
            <ValidationProvider name="'mot de passe'" rules="required|min:4" v-slot="{ errors, valid }">
              <v-text-field
                prepend-icon="mdi-lock"
                v-model="password"
                name="password"
                :error-messages="errors"
                :success="valid"
                label="Mot de passe"
                type="password"
                id="field--user-password"
                :disabled="loggingInOrRetrievingUser" />
            </ValidationProvider>
          <input type="hidden" name="client_id" :value="client_id" />
          <input type="hidden" name="redirect_uri" :value="redirect_uri" />
          <input type="hidden" name="scope" :value="scope" />
            <v-btn
              text
              small
              class='pa-1 ma-0 grey--text'
              gray
              @click="showLostPassword">
              <span class='caption'>
                Mot de passe oublié ?
              </span>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              type="submit"
              color="primary"
              id="btn--submit-login"
              :disabled="loggingInOrRetrievingUser || invalid || !validated"
              :loading="loggingInOrRetrievingUser">Me connecter</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>

    <lost-password-form
      v-else-if="form == 'lost-password'"
      @cancel='cancelLostPassword' />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LostPasswordForm from './LostPassword.vue';
import { getQueryParam } from '@/_helpers';
import i18n from '@/i18n.js';
import store from '@/store';

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  components: {
    LostPasswordForm,
    ValidationProvider,
    ValidationObserver
  },

  data () {
    return {
      email: '',
      password: '',
      form: 'login',
    }
  },
  computed: {
    ...mapGetters({
      loggingIn: 'loading/loggingIn',
      retrievingUser: 'loading/retrievingUser',
      userLoggedIn: "auth/loggedIn",
    }),
    loggingInOrRetrievingUser() {
      return this.loggingIn || this.retrievingUser
    },
    client_id: () => process.env.VUE_APP_AUTH_USERNAME,
    redirect_uri: () => `${process.env.VUE_APP_BASE_URL}/auth/callback`,
    scope: () => process.env.VUE_APP_OAUTH_SCOPES,
  },

  created () {
    if( getQueryParam("error") ) {
      let message = i18n.t(`errors.${getQueryParam("error")}`);
      if( getQueryParam("error_description") ) {
        message += " ("+getQueryParam("error_description")+")";
      }
      store.dispatch('alert/error',  message, { root: true });
    }

    // reset login status
    this.clearSession();
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      clearSession: 'auth/clearSession',
    }),
    submit () {
      const form = this.$refs.observerLoginForm.$el.children[0];
      form.action = `${process.env.VUE_APP_LOOPEAT_BASE_URL}/api/v1/restaurant/auth/login`;
      form.method = 'POST';
      form.submit();
    },
    showLostPassword() {
      this.form = 'lost-password'
    },
    cancelLostPassword() {
      this.form = 'login'
    }
  },

  watch: {
    userLoggedIn: function (value) {
      if (value)
        this.$router.push('/home')
    },
  },
};
</script>
