const state = {
  loggingIn: false,
  retrievingUser: false,
  updatingCurrent: false,
  updatingPassword: false,
  requestingForgotPassword: false,
  requestingResetPassword: false,
  retrievingInvoices: false,
  retrievingTransactions: false,
  retrievingRestaurants: false,
  checkingGuestEmail: false,
  submittingRequest: false,
  waitingRequest: false,
  savingRestaurant: false,
  retrievingPartner: false,
  savingTransaction: false,
};
const actions = {
  reset({ commit }) {
    commit('resetAll');
  },
  updateStatus({ commit }, { key, value }) {
    commit('updateStatus', { key, value });
  }
};
const mutations = {
  updateStatus(state, { key, value }) {
    state[key] = value;
  },
  resetAll(state) {
    Object.keys(state).forEach(function(index) {
      state[index] = false
    });
  }
};

const getters = {
  loggingIn: state => state.loggingIn,
  retrievingUser: state => state.retrievingUser,
  updatingCurrent: state => state.updatingCurrent,
  updatingPassword: state => state.updatingPassword,
  requestingForgotPassword: state => state.requestingForgotPassword,
  requestingResetPassword: state => state.requestingResetPassword,
  retrievingTransactions: state => state.retrievingTransactions,
  retrievingInvoices: state => state.retrievingInvoices,
  retrievingRestaurants: state => state.retrievingRestaurants,
  checkingGuestEmail: state => state.checkingGuestEmail,
  submittingRequest: state => state.submittingRequest,
  waitingRequest: state => state.waitingRequest,
  savingRestaurant: state => state.savingRestaurant,
  retrievingPartner: state => state.retrievingPartner,
  savingTransaction: state => state.savingTransaction,
}

export const loading = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
