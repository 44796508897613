import "babel-polyfill";
import "./plugins/sentry";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./plugins/appConfig";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";

import "./plugins/vee-validate";
import i18n from "./i18n";

import interceptorsSetup from "./_helpers/interceptors/axios";
interceptorsSetup();

import Default from "./views/layouts/Default";
import Empty from "./views/layouts/Empty";
import NoNavbar from "./views/layouts/NoNavbarWithLogo";

Vue.component("default-layout", Default);
Vue.component("empty-layout", Empty);
Vue.component("no-navbar-layout", NoNavbar);

import "./plugins/qrcodeReader";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
