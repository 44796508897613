import axios from 'axios';
import { authService } from '@/_services'

import store from '@/store'
import i18n from '@/i18n.js';

function isExpiredTokenError(data) {
  return (
    data.errors && (
      data.errors.find(error => error.code == "expired_token")
      || data.errors.find(error => error.code == "invalid_token" && error.detail == 'Le jeton d\'accès a expiré')
    )
  );
}

function isExpiredRefreshTokenError(data) {
  return (data.errors && data.errors.find((error) => error.code == 'expired_refresh_token'))
}

function isBadCredentialError(data) {
  return (data.errors && data.errors.find((error) => error.code == 'bad_credentials'))
}

function storeToken({ access_token, refresh_token, created_at, expires_in }) {
  store.commit('auth/loginSuccess', { access_token, refresh_token, created_at, expires_in }, {root: true});
  axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
}

function refreshExpiredToken(originalConfig) {
  originalConfig._retry = true;

  const storedRefreshToken = store.state.auth.refreshToken

  return new Promise((resolve) => {
    authService.refreshToken(storedRefreshToken)
      .then(response => {
        const oauthToken = response
        storeToken(oauthToken)
        return resolve(axios(originalConfig));
      })
  })
}

const requestInterceptor = axios.interceptors.request.use(function (config) {
  const accessToken = store.state.auth.accessToken;

  if (accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`;

  return config;

}, function (err) {
  return Promise.reject(err);
});

const successResponseInterceptor = response => {
  const type = response.headers['content-type']

  if (type.includes('json')) {
    // "data" is the root key of the API's json response
    if ('data' in response.data)
      return response.data['data']
    else
      return response.data
  }
  else
    return response
}

const errorResponseInterceptor = error => {
  const originalConfig = error.config;

  if (error.response.status === 403) {
    store.dispatch('auth/forbidAccess')
    return Promise.reject(error);

  } else if (error.response.status === 401) {
    if (isExpiredTokenError(error.response.data) && !originalConfig._retry) {
      return refreshExpiredToken(originalConfig)

    } else if (isBadCredentialError(error.response.data)) {
      return Promise.reject(error);

    } else if (isExpiredRefreshTokenError(error.response.data)) {
      delete axios.defaults.headers.common['Authorization']
      store.dispatch('auth/logout', { error: i18n.t('errors.session_expired_need_to_relog') })
      return new Promise(() => {});  

    } else {
      store.dispatch('auth/logout', { error: i18n.t('errors.something_went_wrong') })
    
      return new Promise(() => {});
    }
  }

  return Promise.reject(error);
}

const responseInterceptor = axios.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export default function setup() {
  requestInterceptor,
  responseInterceptor
}
