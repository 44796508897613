<template>
  <table class="stock-table">
    <tbody>
      <tr v-for="(item, index) in stocks" v-bind:key="index">
        <td style="width: 80px" class="pr-2">
          <v-img
            :src="item.image_url"
            :alt="item.title"
            width="80px"
          ></v-img>
        </td>
      <td class="pl-1">
          <span class="font-weight-bold black--text">{{ item.title }}</span
          ><br />
          <span class="text-truncate">{{ item.subtitle }}</span
          ><br />
          <span class="text-truncate">{{ `${item.amount} €` }}</span>
        </td>
        <td class="text-right pr-2">
          <span class="font-weight-bold text-h6 black--text">
            <slot :item="item">{{
              item.containers_count
            }}</slot>
          </span>
        </td>
        <slot name="more" :item="item"></slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "StocksTabItem",
  props: {
    stocks: {
      type: Array
    }
  }
}
</script>
