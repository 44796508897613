<template>
  <v-container class="pa-0">
    <v-alert outlined text type="success" v-if="$slots.alert" class="mb-0">
      <slot name="alert"> </slot>
    </v-alert>
    <template v-else>
      <v-row
        v-for="(item, index) in availableFormats"
        v-bind:key="index"
        no-gutters
        style="flex-wrap: nowrap"
      >
        <v-col
          cols="1"
          style="min-width: 60px"
          class="flex-grow-0 flex-shrink-0"
        >
          <v-img :src="item.image_url" :alt="item.title" width="60px"></v-img>
        </v-col>
        <v-col
          cols="6"
          style="min-width: 100px; max-width: 100%; line-height:18px"
          class="flex-grow-1 flex-shrink-0 px-2"
        >
          <span class="font-weight-bold text-truncate">{{ item.title }}</span
          ><br />
          <span class="text-truncate">{{ item.subtitle }}</span><br />
          <span class="text-truncate">{{ `${item.amount} €` }}</span>
        </v-col>
        <v-col
          cols="4"
          style="min-width: 104px; max-width: 100%"
          class="flex-grow-0 flex-shrink-1"
        >
          <counter
            :disabled="disabled"
            :value="formatValue(item.id)"
            :maxValue="formatMaxValue(item.id)"
            @update-count="updateCount($event, item.id)"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Counter from "./Counter.vue";

export default {
  components: { Counter },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    availableFormats: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateCount(value, formatId) {
      this.$emit("update-format-count", {
        value: value,
        formatId: formatId,
        action: this.action
      });
    },

    formatValue(id) {
      return this.value[id];
    },

    formatMaxValue(id) {
      let format = this.availableFormats.find((format) => format.id == id);

      if (format) {
        return format.containers_count;
      } else {
        return 0;
      }
    }
  }
};
</script>
