import { authService } from '@/_services';
import router from '@/router';
import i18n from '@/i18n';

const getDefaultState = () => {
  let accessToken, refreshToken, thirdPartyRequest, expiresAt = null

  if (localStorage.getItem('token')) {
    try {
      let storedToken = JSON.parse(localStorage.getItem('token'))
      accessToken = storedToken['access_token']
      refreshToken = storedToken['refresh_token']
      expiresAt = storedToken['expires_at']
    } catch(e) {
      console.error(e)
    }
  }

  if (localStorage.getItem('third_party_request')) {
    try {
      thirdPartyRequest = JSON.parse(localStorage.getItem('third_party_request'))
     } catch(e) {
       console.error(e)
     }
   }

  let state = {
    accessToken: accessToken,
    refreshToken: refreshToken,
    expiresAt: expiresAt,
    thirdPartyRequest: thirdPartyRequest,
  }

  return state
}
const state = getDefaultState()

const actions = {
  async exchangeCodeForToken({ dispatch, commit }, code){
    commit('loading/updateStatus', { key: 'loggingIn', value: true }, { root: true })
    await authService.exchangeCodeForToken(code).then(async response => {
      if( response.error ){
        commit('loginFailure', response.error)
        dispatch('alert/error', i18n.t('errors.something_went_wrong') + "("+response.error_description+")", { root: true });
      } else {
        commit('loginSuccess', response)
        await dispatch('user/current', null, {root: true});
        await authService.appInfo().then(app => commit('storeAppInfo', app));
      }
    }).catch(error => {
      console.error(error);
      dispatch('alert/error', i18n.t('errors.something_went_wrong'), { root: true });
      commit('loginFailure', error)
    }).finally(() => {
      commit('loading/updateStatus', { key: 'loggingIn', value: false }, { root: true })
    });
  },
  
  rememberThirdPartyRequest({ commit }, request){
    commit('rememberThirdPartyRequest', request);
   },

  clearThirdPartyRequest({ commit }){
    commit('clearThirdPartyRequest');
  },

  logout({ dispatch, commit }, options) {
    authService.logout();
    setTimeout(() => {
      router.push('/');
    })

    commit('logout');

    dispatch('user/reset', null, { root: true });
    dispatch('restaurants/reset', null, { root: true });
    dispatch('loading/reset', null, { root: true });

    if (options && options.error) {
      dispatch('alert/error', options.error, { root: true });
    }
  },
  clearSession({ commit }) {
    authService.logout();
    commit('logout');
  },

  reset ({ commit }) {
    commit('resetState')
  },

  forbidAccess({ dispatch }) {
    dispatch('auth/logout', null, { root: true })
  }
};

const mutations = {

  rememberThirdPartyRequest(state, request) {
    state.thirdPartyRequest = request
    localStorage.setItem('third_party_request', JSON.stringify(request));
 },

  clearThirdPartyRequest(state) {
    state.thirdPartyRequest = null
    localStorage.removeItem('third_party_request');
  },

  loginSuccess(state, response) {
    state.accessToken = response['access_token']
    state.refreshToken = response['refresh_token']
    state.expiresAt = response['created_at'] + response['expires_in']

    localStorage.setItem('token', JSON.stringify({
      access_token: response['access_token'],
      refresh_token: response['refresh_token'],
      expires_at: state.expiresAt,
    }));
  },

  storeAppInfo(state, app) {
    state.app = app;
    localStorage.setItem(
      "token",
      JSON.stringify({
        access_token: state.accessToken,
        refresh_token: state.refreshToken,
        expires_at: state.expiresAt,
      })
    );
  },

  loginFailure(state) {
    state.accessToken = null
    state.refreshToken = null
    state.expiresAt = null

    localStorage.removeItem('token')
  },

  logout(state) {
    state.accessToken = null
    state.refreshToken = null
    state.expiresAt = null
  },

  setConfirmCsrfToken(state, confirmCsrfToken){
    state.confirmCsrfToken = confirmCsrfToken;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

const getters = {
  loggedIn: state => !!(state.accessToken && state.refreshToken),
  accessToken: state => state.accessToken,
  pendingThirdPartyRequest: state => state.thirdPartyRequest !== null,
  thirdPartyRequestQuery: state => state.thirdPartyRequest ? Object.keys(state.thirdPartyRequest).map(k => [k, state.thirdPartyRequest[k]].join('=')).join('&') : '',
}

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
