import axios from 'axios';
import { displayError } from '@/_helpers/alert'

export const axiosFetcher = (url, options) => {
  const requestConfig = axiosRequestOptions(Object.assign({ url }, options))

  return axios(requestConfig)
    .catch(error => {
      if (!error.response) {
        const message = 'Veuillez vérifier votre connection internet et actualiser la page.'
        displayError(message)
        throw new Error(message)
      } else {
        throw error
      }
    })
}

const axiosRequestOptions = (options = {}) => {
  var opts = Object.assign({}, options);
  var headers = Object.assign({}, opts.headers);

  opts.baseURL = opts.baseURL || `${process.env.VUE_APP_LOOPEAT_BASE_URL}/api/v1/restaurant`;
  opts.method = opts.method || 'get';

  // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
  // this method is no longer supported
  if (opts.auth_username || opts.auth_password) {
    throw 'Do not use basic auth'
  }

  opts.headers = headers

  delete opts.auth_username
  delete opts.auth_password
  delete opts.token

  return opts
}
