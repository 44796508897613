<template>
  <div>
    <p class="text-center text-h6 mb-6" style="line-height: 1.8rem"
      v-if="requestSuccessSummary.deposit_change && !requestSuccessSummary.guest">
      {{ $t("request.summary.request_confirmed") }}
      <br />

      <span v-if="requestSuccessSummary.deposit_change">
        {{ totalCostText }}
      </span>
    </p>

    <table-summary v-model="requestSuccessSummary" />

    <div class="d-flex flex-column align-content-space-around flex-wrap py-2 mt-8">
      <v-btn rounded large class="px-8" color="primary" @click="close">
        {{ $t("shared.close") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TableSummary from "./TableSummary.vue";

export default {
  components: {
    TableSummary
  },

  methods: {
    close() {
      this.$emit("close");
    }
  },

  computed: {
    ...mapState({
      requestSuccessSummary: state => state.request.summary
    }),

    ...mapGetters("request", [
      "isCardHolder",
      "accountCardMaxDeposit"
    ]),

    absoluteDepositChange() {
      if (this.requestSuccessSummary.deposit_change)
        return Math.abs(this.requestSuccessSummary.deposit_change);

      return null;
    },

    totalCostText() {
      const cardSuffix = this.isCardHolder ? "_card" : "";
      const mode = this.requestSuccessSummary > 0 ? "refund" : "take";

      return this.$tc(
        `request.total_cost_to_${mode}${cardSuffix}`,
        this.requestSuccessSummary.deposit_change,
        {
          amount: `${this.absoluteDepositChange}€`
        }
      );
    }
  }
};
</script>
