<template>
  <v-app
    style="
      background: #f7f7f7;
      background-image: linear-gradient(
        to right top,
        #f7f7f7,
        #f5f6f5,
        #f4f4f4,
        #f2f3f2,
        #f1f1f1,
        #f0f0f0,
        #f0f0f0,
        #efefef,
        #efefef,
        #f0f0f0,
        #f0f0f0,
        #f0f0f0
      );
    "
  >
    <alert-snackbar></alert-snackbar>

    <new-version-snackbar
      id="alert--bottom"
      :bottom="true"
    ></new-version-snackbar>
    <offline v-if="!isOnline" />

    <component v-if="initComplete" v-show="isOnline" :is="layout">
      <router-view />
    </component>
    <div v-else class="d-flex justify-center align-center" style="height:100vh">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <v-dialog
      v-model="restaurantError"
    >
      <v-card tile>
        <v-card-title class="justify-center">
          <v-icon class="mr-4">mdi-alert-circle-outline</v-icon> Aucun restaurant actif pour ce compte
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="restaurants"
      v-model="selectRestaurantDialog"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          <span class="text-h5">Selectionner votre restaurant</span>
        </v-card-title>
        <v-card-text>
          <select-restaurant
            @restaurant-selected="restaurantSelected"
            :restaurants="restaurants"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import AlertSnackbar from "@/components/AlertSnackbar.vue";
import NewVersionSnackbar from "@/components/NewVersionSnackbar.vue";
import Offline from "@/views/Offline";
import SelectRestaurant from "@/views/private/SelectRestaurant";

const default_layout = "default";

export default {
  data: () => ({
    initComplete: false,
    selectRestaurantDialog: false,
    restaurantError: false,
  }),
  components: {
    AlertSnackbar,
    Offline,
    NewVersionSnackbar,
    SelectRestaurant
  },
  created() {
    this.updateOnlineStatus(navigator.onLine);

    window.addEventListener("online", () => {
      this.updateOnlineStatus(true);

      setTimeout(() => {
        this.updateOnlineStatus(true);
      }, 1000);
    });

    window.addEventListener("offline", () => {
      this.updateOnlineStatus(false);
    });

    this.init();
  },
  name: "app",
  computed: {
    ...mapGetters({
      isOnline: "online/isOnline",
      userLoggedIn: "auth/loggedIn"
    }),
    ...mapState({
      onlineStatus: (state) => state.online.status,
      restaurants: (state) => state.restaurants.restaurants
    }),
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
  methods: {
    ...mapActions({
      updateOnlineStatus: "online/update",
      fetchUser: "user/current",
      fetchRestaurants: "restaurants/index",
      fetchCurrentRestaurantStocks: "restaurants/fetchCurrentRestaurantStocks"
    }),

    ...mapMutations({
      setCurrentRestaurantId: "restaurants/setCurrentRestaurantById"
    }),
    init() {
      if (this.userLoggedIn) {
        this.fetchInitialData();
      } else {
        this.initComplete = true;
      }
    },
    fetchInitialData() {
      this.fetchUser().then(() => {
        this.fetchRestaurants().then(() => {
          this.initComplete = this.restaurants.length == 1;
          this.selectRestaurantDialog = this.restaurants.length > 1;
          this.restaurantError = this.restaurants.length == 0;
        });
      });
    },

    restaurantSelected() {
      this.fetchCurrentRestaurantStocks();
      this.initComplete = true;
      this.selectRestaurantDialog = false;
    }
  },

  watch: {
    userLoggedIn(value) {
      if (value) this.fetchInitialData();
    }
  }
};
</script>

<style lang="scss">
@media only screen and (max-device-width: 480px) {
  select:focus,
  input,
  textarea {
    font-size: 16px !important;
  }
}

// https://github.com/vuetifyjs/vuetify/issues/9130
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.w-1 {
  width: 1%;
}

.w-50 {
  max-width: 50%;
}

.w-100 {
  width: 100%;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-cursor {
  cursor: pointer !important;
}

.pill-counter {
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 100px;
  background-color: var(--v-primary-base) !important;
  width: 104px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .count-value {
    font-size: 20px;
    font-weight: 500;
    width: 24px;
    text-align: center;
    display: inline-block;
    background: white;
    border-radius: 4px;
  }
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 10px !important;
}

.v-tabs .v-tab--active::before {
  opacity: 0 !important;
}

.position-bottom-fixed {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
}
</style>
