<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }} icon</title>
    <g :fill="iconColor">
      <path d="M1.64,19a.65.65,0,0,1-.22-1.26l6.77-2.5a.65.65,0,1,1,.45,1.22L1.87,19A.62.62,0,0,1,1.64,19Z" transform="translate(-0.99 -3.82)"/>
      <path d="M2.34,15.87a.66.66,0,0,1-.61-.42.66.66,0,0,1,.38-.84L6.72,12.9a.65.65,0,1,1,.45,1.22l-4.6,1.71A.62.62,0,0,1,2.34,15.87Z" transform="translate(-0.99 -3.82)"/>
      <path d="M6.59,20.18A.66.66,0,0,1,6,19.76a.65.65,0,0,1,.39-.83L11,17.21a.65.65,0,1,1,.46,1.22L6.82,20.14A.62.62,0,0,1,6.59,20.18Z" transform="translate(-0.99 -3.82)"/>
      <path d="M14.73,10l0,.09a.12.12,0,0,1,0-.07Z" transform="translate(-0.99 -3.82)"/>
      <path d="M17.27,6a1.88,1.88,0,0,0-.14-1.09A1.84,1.84,0,0,0,13.8,6.43l0,0a11.2,11.2,0,0,1-2.06-.89h0a8.25,8.25,0,0,1-.62,2.2,11.35,11.35,0,0,1-.9,2.06,5.68,5.68,0,0,1,1.33.35l2.88-1,.79-.28.76-.28L17.32,8l1.25-.45L19,7.37l.2-.07.32-1.74Z" transform="translate(-0.99 -3.82)"/>
      <path d="M19.56,15.48l-3.22,1.17a5.18,5.18,0,0,1-1.8.32,5.24,5.24,0,0,1-4.92-3.45L9.08,12,22.15,7.27l.54,1.5A5.24,5.24,0,0,1,19.56,15.48Z" transform="translate(-0.99 -3.82)"/>
    </g>
  </svg>
</template>

<script>
export default {
  data: () => ({
    iconName: 'Borrow'
  }),

  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>