import { transactionsService } from '@/_services';
import { formatDate } from '@/_helpers';

const state = { transactions: [] };

const actions = {
  index({ commit }, restaurant_uuid) {
    commit('loading/updateStatus', { key: 'retrievingTransactions', value: true }, { root: true })

    return transactionsService.index(restaurant_uuid)
      .then(transactions => {
        commit('getTransactionsSuccess', transactions);
      }).finally(() => {
        commit('loading/updateStatus', { key: 'retrievingTransactions', value: false }, { root: true })
      });
  },

  clearTransactions({ commit }) {
    commit('clearTransactions')
  }
};

const getters = {
  groupedTransactions: state => {
    if (state.transactions) {
      return state.transactions.reduce((obj, transaction) => {
        const refDate = transaction.completed_at || transaction.created_at
        const date = new Date(refDate)
        const index = formatDate(date, 'DD-MM-YYYY')

        // If the integer doesn't already exist as a key in the object, create it
        if (!Object.prototype.hasOwnProperty.call(obj, index)) {
          obj[index] = [];
        }

        // Push the number to its integer key
        obj[index].push(transaction);

        // Pass the object on to the next loop
        return obj;
      }, {});
    } else {
      return []
    }
  }
}

const mutations = {
  getTransactionsSuccess(state, transactions) {
    state.transactions = transactions;
  },

  clearTransactions(state) {
    state.transactions = []
  }
};

export const transactions = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
