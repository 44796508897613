<template>
  <div>
    <p class="text-h6 mb-8">{{ $t("request.summary.title") }}</p>

    <table v-if="value" class="request-summary-table w-100">
      <tr v-if="value.returns">
        <td class="w-1">
          <return-icon :width="24" :height="24" class="mr-4"></return-icon>
        </td>
        <td>
          <span class="text-subtitle-1">{{ $t("request.summary.grab_containers") }}</span>
          <ul>
            <li v-for="(item, index) in value.returns" :key="`return_${index}`">
              {{ item.quantity }} x {{ item.title }}
            </li>
          </ul>
        </td>
      </tr>

      <tr v-if="value.grabs">
        <td class="w-1">
          <borrow-icon :width="24" :height="24" class="mr-4"></borrow-icon>
        </td>
        <td>
          <span class="text-subtitle-1">{{ $t("request.summary.serve_in_containers") }}</span>
          <ul>
            <li v-for="(item, index) in value.grabs" :key="`grab_${index}`">
              {{ item.quantity }} x {{ item.title }}
            </li>
          </ul>
        </td>
      </tr>

      <tr v-if="value.refills">
        <td class="w-1">
          <refill-icon :width="24" :height="24" class="mr-4"></refill-icon>
        </td>
        <td>
          {{ $tc(`request.total_to_refill`, value.refills, { amount: value.refills }) }}
        </td>
      </tr>

      <tr v-if="value.redeem_offer">
        <td class="w-1">
          <offer-icon :width="24" :height="24" class="mr-4"></offer-icon>
        </td>
        <td>
          Faites profiter le client de votre offre spéciale&nbsp;:
          {{ value.offer_description }}
        </td>
      </tr>

      <tr v-if="value.activate_card">
        <td class="w-1">
          <v-icon color="black" width="24" height="24" class="mr-4">mdi mdi-cards</v-icon>
        </td>
        <td>
          <span class="text-subtitle-1">{{ $t("request.summary.activated_card") }}</span>
          <ul>
            <li>{{ $t("request.summary.give_card") }}</li>
            <li>{{ $t(`request.activation_total`, { amount: value.account_card_max_deposit }) }}</li>
          </ul>
        </td>
      </tr>

      <tr v-if="value.deactivate_card">
        <td class="w-1">
          <v-icon color="black" width="24" height="24" class="mr-4">mdi mdi-cards</v-icon>
        </td>
        <td>
          <span class="text-subtitle-1">{{ $t("request.summary.deactivated_card") }}</span>
          <ul>
            <li>{{ $t("request.summary.take_card") }}</li>
            <li>{{ $tc(`request.refund_total`, value.refund_amount, { amount: value.refund_amount }) }}</li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import BorrowIcon from "@/components/icons/BorrowIcon.vue";
import OfferIcon from "@/components/icons/OfferIcon.vue";
import RefillIcon from "@/components/icons/RefillIcon.vue";
import ReturnIcon from "@/components/icons/ReturnIcon.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    BorrowIcon,
    OfferIcon,
    RefillIcon,
    ReturnIcon,
  }
};
</script>

<style lang="scss">
.request-summary-table {
  tr td {
    border: solid;
    border-width: 0 0 thin 0;
    border-color: rgba(0, 0, 0, 0.12);
    padding: 10px 0;
  }

  tr:last-of-type td {
    border: none;
  }
}
</style>
