<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile elevation="0">
      <v-toolbar flat>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-if="step === steps.SELECT_FORMATS">
        <v-card-text class="px-0 pb-16">
          <email-inputer
            v-if="value"
            @close="cancel"
            @goto-review="gotoReview"
          />
        </v-card-text>
      </div>

      <div v-if="step === steps.REVIEW">
        <v-card-text>
          <preview-summary @cancel="backToSelectFormat" />
        </v-card-text>
      </div>

      <div v-if="step === steps.SUCCESS">
        <v-card-text>
          <success-summary v-if="steps.SUCCESS" @close="close" />
        </v-card-text>
      </div>

      <div v-if="step === steps.ERROR"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

import EmailInputer from "@/components/request/EmailInputer.vue";
import SuccessSummary from "@/components/request/SuccessSummary.vue";
import PreviewSummary from "@/components/request/PreviewSummary.vue";

const initialData = () => ({
  step: "select-formats"
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    EmailInputer,
    SuccessSummary,
    PreviewSummary
  },

  data: function () {
    return Object.assign(
      {
        steps: {
          SELECT_FORMATS: "select-formats",
          REVIEW: "request-review",
          SUCCESS: "request-success",
          ERROR: "request-error"
        }
      },
      initialData()
    );
  },

  methods: {
    ...mapActions({
      clearRequest: "request/clear"
    }),

    backToSelectFormat() {
      this.step = this.steps.SELECT_FORMATS;
    },

    gotoReview() {
      this.step = this.steps.REVIEW;
    },

    close() {
      this.closeValidator();
    },

    cancel() {
      this.closeValidator();
    },

    closeValidator() {
      this.clearRequest();

      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.$emit("close");
    }
  },

  computed: {
    ...mapState({
      requestStatus: (state) => state.request.status,
      requestError: (state) => state.request.error
    })
  },

  watch: {
    requestStatus(status) {
      if (this.value)
        if (status == "success") {
          this.step = this.steps.SUCCESS;
        } else if (status == "error") {
          this.step = this.steps.ERROR;
        }
    }
  }
};
</script>
