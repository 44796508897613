<template>
  <v-snackbar dark :value="showRefresh" :color="'grey darken-4'" :timeout="-1">
    {{ $t("new_version") }}
    <v-btn icon @click="refresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "NewVersionSnackbar",

  data: () => ({
    showRefresh: false,
  }),

  created() {
    window["isUpdateAvailable"].then((isAvailable) => {
      if (isAvailable) this.showRefresh = true;
    });
  },

  methods: {
    refresh() {
      window.location.reload();
    },
  }
};
</script>
