<template>
  <v-card class="mt-0 mt-sm-4 px-2 px-sm-0">
    <v-toolbar flat>
      <v-toolbar-title>Historique des transactions</v-toolbar-title>
    </v-toolbar>
    <div v-if='retrievingTransactions' class='text-center'>
      <v-card-text>
        <v-progress-circular
          indeterminate />
      </v-card-text>
    </div>
    <div v-else>
      <div
        v-if='anyTransactions'>
        <v-simple-table
          v-for='date in Object.keys(groupedTransactions)'
          :key='date'>
          <tbody>
            <tr>
              <td colspan="4">
                {{ date }}
              </td>
            </tr>
            <tr
              v-for='item in groupedTransactions[date]'
              :key='item.id'>
              <td class='pa-2' width='1'>
                <v-icon small>{{ statusToIcon(item.status) }}</v-icon>
              </td>
              <td class='pa-2' width='1'>{{ formatDate(item.created_at, 'hh:mm') }}</td>
              <td class='pa-2'>{{ $t(`transactions.${item.transaction_type}`) }}</td>
              <td class='pa-2 text-right' width='1'>{{ item.amount }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <v-card-text
        v-else>
        <v-alert
          dense
          outlined
          type="info"
        >
          Aucune transaction trouvée
        </v-alert>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState } from "vuex";
  import { formatDate } from '@/_helpers';

  export default {
    data: () => ({}),

    mounted() {
      const uuid = this.currentRestaurant.uuid;
      this.fetchTransactions(uuid);
    },
    computed: {
      ...mapGetters({
        currentRestaurant: "restaurants/currentRestaurant",
        retrievingTransactions: 'loading/retrievingTransactions',
        groupedTransactions: 'transactions/groupedTransactions',
      }),
      ...mapState({
        transactions: (state) => state.transactions.transactions,
      }),
      anyTransactions() {
        return this.transactions.length > 0
      },
    },

    methods: {
      ...mapActions({
        fetchTransactions: "transactions/index",
      }),
      statusToIcon(value) {
        if (value == "active") return "mdi-check";
        if (value == "pending") return "mdi-clock-outline";
        if (value == "completed") return "mdi-check";
        if (value == "failed") return "mdi-alert-circle-outline";
        if (value == "cancelled") return "mdi-cancel";
        if (value == "inactive") return "mdi-pause";

        return '-'
      },
      formatDate(dateStr, format) {
        const date = new Date(dateStr)
        return formatDate(date, format)
      }
    },
  };
</script>
