<template>
  <v-main>
    <v-container fluid>
      <v-row
        justify="center">
        <v-col
          cols="12"
          sm="8"
          md="4">

          <router-view />

          <app-footer />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AppFooter from './Footer'


export default {
  components: {
    AppFooter
  },

  methods: {
    success() {
      this.$router.push('/lunchbox')
    }
  }
}
</script>